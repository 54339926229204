import React, { useEffect } from 'react';
import Routes from './Routes';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/authActions';
import { loadCart } from './actions/cartActions';
import './App.css';
import { LOGOUT } from './constants/authConstants';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

let theme = createTheme({
  palette: {
    primary: {
      main: '#82CCDD',
    },
    secondary: {
      main: '#c00044',
    },
  },
});

theme = responsiveFontSizes(theme);

const tagManagerArgs = {
  gtmId: GTM_ID,
  // gtmId: 'GTM-TX4GCT8',
  dataLayer: {
    userId: '001',
    userProject: 'project',
  },
};

const App = () => {
  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
    //user not logged but there is a cart in local state
    if (!localStorage.token && localStorage.cart) store.dispatch(loadCart());
  }, []);
  const queryClient = new QueryClient();

  return (
    <div>
      <Provider store={store}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HelmetProvider>
      </Provider>
    </div>
  );
};

export default App;
