import {
  Avatar,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarOrder from '../components/nav/AppBarOrder';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import TitleScreen from '../components/layout/TitleScreen';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  container: {
    // marginBottom: theme.spacing(5),
  },
  sideTitle: {
    alignSelf: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    float: 'right',
  },
}));

const List = ({ listItemsArr }) => {
  const classes = useStyles();
  return listItemsArr.map((i, idx) => (
    <ListItem key={idx}>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{idx + 1}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={i} />
    </ListItem>
  ));
};

const ReturningUserInfoScreen = () => {
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        <title>Info</title>
      </Helmet>
      <AppBarOrder />
      <Container>
        <TitleScreen
          title='Pomoc w logowaniu do panelu FitDiet'
          align='center'
        />
        <section>
          <Grid container spacing={3} className={classes.container}>
            <Grid item md={6} className={classes.sideTitle}>
              <Typography
                variant='h5'
                className={classes.title}
                color='secondary'
              >
                Korzystałem wcześniej z FitDiet!
              </Typography>
              <Typography variant='h5' color='textSecondary'>
                Jeśli wcześniej zamawiałeś z FitDiet to jest duża szansa, że
                Twoje konto zostało utworzone na naszej platformie! Skorzystaj z
                instrukcji obok, żeby zalogować się na swoje konto
              </Typography>
            </Grid>
            <Grid item md={6}>
              <List
                listItemsArr={[
                  'Wybierz opcję „Zapomniałeś hasła?”',
                  'Podaj swój adres mailowy wprowadzony przy rejestracji oraz kliknij pole „Wyślij link do resetu hasła”',
                  'Po chwili otrzymasz na swoją skrzynkę pocztową wiadomość z linkiem do zmiany hasła ważnym przez 15 min. Po kliknięciu w link będziesz mógł ustalić nowe hasło',
                  'Po zaakceptowaniu nowego hasła możesz przejść do opcji logowania',
                  'Gotowe! Już jesteś na swoim Panelu klienta, sprawdź jego wszystkie możliwości',
                ]}
              />

              <Link to='/odzyskiwanie-hasla' className={classes.link}>
                Przejdź do odzyskiwania hasła
              </Link>
            </Grid>
          </Grid>
          <hr className={classes.divider} />
          <Grid container spacing={3} className={classes.container}>
            <Grid item md={6} className={classes.sideTitle}>
              <Typography
                variant='h5'
                className={classes.title}
                color='secondary'
              >
                Nie korzystałem wcześniej z FitDiet!
              </Typography>
              <Typography
                variant='h5' //className={classes.title}
                color='textSecondary'
              >
                Jeśli pierwszy raz chcesz złożyć zamówienie z FitDiet to
                postępuj zgodnie z instrukcją obok
              </Typography>
            </Grid>
            <Grid item md={6}>
              <List
                listItemsArr={[
                  'Wybierz opcję „Nie masz konta - zarejestruj się”',
                  'Uzupełnij wszystkie pola i zapisz zmiany',
                  'Gotowe! Już jesteś na swoim Panelu klienta, w pierwszej kolejności uzupełnij swoje dane adresowe, abyś mógł zrealizować pierwsze zamówienie',
                ]}
              />
              <Link to='/rejestracja' className={classes.link}>
                Przejdź do rejestracji nowego użytkownika
              </Link>
            </Grid>
          </Grid>
          <hr className={classes.divider} />
          <Grid container spacing={3} className={classes.container}>
            <Grid item md={6} className={classes.sideTitle}>
              <Typography
                variant='h5'
                className={classes.title}
                color='secondary'
              >
                Co mogę zrobić w panelu?
              </Typography>
              <Typography
                variant='h5' //className={classes.title}
                color='textSecondary'
              >
                W panelu możesz sprawdzić aktualne diety, zamówienia oraz
                wprowadzić zmiany w swoich danych
              </Typography>
            </Grid>
            <Grid item md={6}>
              <List
                listItemsArr={[
                  '„Moje diety”- aktualnie trwające zamówienia, podgląd menu, składanie nowych zamówień',
                  '„Moje zamówienia”- historia Twoich zamówień',
                  '„Moje konto”- edycja danych kontaktowych, zmiana hasła edycja i dodawanie nowych adresów dostawy',
                ]}
              />
              <Link to='/' className={classes.link}>
                Przejdź do panelu użytkownika
              </Link>
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

export default ReturningUserInfoScreen;
