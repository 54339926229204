import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_CLEAR_ERROR } from '../constants/authConstants';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';

import { login } from '../actions/authActions';
import { Redirect } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MUILink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import AppBarOrder from '../components/nav/AppBarOrder';
import Message from '../components/layout/Message';
import Loader from '../components/layout/Loader';
import ReturningUserInfo from '../components/ReturningUserInfo';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Wszystkie prawa zastrzeżone © '}
      <MUILink color='inherit' href='https://www.fitdiet.pl/'>
        Fitdiet{' '}
      </MUILink>
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ location }) => {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    loading,
    profile,
    error,
    message,
    isPasswordChanged,
  } = useSelector((state) => state.auth);
  let history = useHistory();
  const queryStr = queryString.parse(location.search);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { email, password } = values;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const clickSubmit = async (event) => {
    event.preventDefault();
    dispatch(login(email.toLowerCase(), password));
  };

  useEffect(() => {
    if (isAuthenticated && profile) {
      const { redirect } = queryStr;
      const newUrl = redirect ? redirect : '/';
      history.push(newUrl);
    }
  }, [isAuthenticated, profile]);

  return (
    <>
      <Helmet>
        <title>Logowanie</title>
      </Helmet>
      <AppBarOrder />
      <ReturningUserInfo />
      {(error || message) && (
        <Message
          message={error || message}
          type={error ? 'error' : 'success'}
          handleClose={() => dispatch({ type: AUTH_CLEAR_ERROR })}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Zaloguj się
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Adres email'
                name='email'
                autoComplete='email'
                autoFocus
                onChange={handleChange}
                value={email}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Hasło'
                type='password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={handleChange}
              />
              {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={clickSubmit}
              >
                Zaloguj
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to='/odzyskiwanie-hasla' variant='body2'>
                    Zapomniałeś hasła?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to='/rejestracja' variant='body2'>
                    {'Nie masz konta - zarejestruj się'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
          {/* <Loader open={loading} /> */}
        </Container>
      )}
    </>
  );
};

export default Login;
