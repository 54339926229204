// import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  mealItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const MyMenu = ({ meals }) => {
  const classes = useStyles();

  return meals && meals[0].mealNameWWW ? (
    meals.map((m) => (
      <div className={classes.mealItem} key={m._id}>
        <Typography variant='body2' component='p' color='secondary'>
          {m.mealLabel.toUpperCase()} <br />
        </Typography>
        <Typography variant='body2' component='p'>
          {m.mealNameWWW}
        </Typography>
        {m.kcal ? (
          <Typography variant='caption'>
            {`kcal: ${parseInt(m.kcal).toFixed()} | B: ${parseInt(
              m.protein
            ).toFixed()}g. | W:${parseInt(m.carbs).toFixed()}g. | T:${parseInt(
              m.fat
            ).toFixed()}g.`}
          </Typography>
        ) : (
          ''
        )}
      </div>
    ))
  ) : (
    <Typography variant='body2' component='p'>
      Wkrótce będzie dostępne
    </Typography>
  );
};

export default MyMenu;
