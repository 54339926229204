import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { API_URL } from '../../config';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  innerBox: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  info: {
    marginLeft: theme.spacing(2),
  },
}));

const DietInfo = ({ orderNr, fullDietName, startAt, endAt, photo }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.innerBox}>
        <div>
          <img
            src={`/diets/${photo}`}
            // src={`${API_URL}/api/v1/uploads/${photo}`}
            // src={`api/v1/uploads/${photo}`}
            width='100'
            alt={`${photo}`}
          />
        </div>
        <div className={classes.info}>
          <Typography variant='body2'>{`Zamówienie # ${orderNr}`}</Typography>
          <Typography mt={0} fontWeight={500} variant='body2'>
            {fullDietName}
          </Typography>
          {/* <Typography variant='body1'>Kolejna dostawa: 26.03.2021</Typography> */}
          <Typography variant='body2'>{`Od: ${format(
            new Date(startAt),
            'dd.MM.yyyy'
          )}`}</Typography>
          <Typography variant='body2'>{`Do: ${format(
            new Date(endAt),
            'dd.MM.yyyy'
          )}`}</Typography>
        </div>
      </Paper>
    </div>
  );
};

export default DietInfo;
