import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import CustomizedRadios from '../layout/CustomizedRadios';
import CustomizedSwitch from '../layout/CustomizedSwitch';
import {
  CART_ITEM_TOGGLE,
  CART_ITEM_MOUSE_ENTER_DAY,
  CART_ITEM_SET_DAYS,
  CART_ITEM_CLEAR_DAYS,
  CART_ITEM_UPDATE_SCHEDULE,
} from '../../constants/cartItemConstants';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from '@mui/styles';
import './ScheduleModule.css';
import { createDisabledDays } from '../../utils/schedule';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const MONTHS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];
const WEEKDAYS_LONG = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
];
const WEEKDAYS_SHORT = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'];
const modifiersStyles = {
  selected: {
    color: 'white',
    backgroundColor: '#82CDDD',
  },
  outside: {
    backgroundColor: '#FAFAFA', //to make outside calendar days transparent
  },
};

const useStyles = makeStyles((theme) => ({
  dayPicker: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const ScheduleModule = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isSaturdayBlocked,
    isSundayBlocked,
    scheduleMode,
    deliveryDays,
    range,
    isTrialDay,
    calories,
  } = useSelector((state) => state.cartItem);
  const { start, end } = range;

  const initDisabledDays = createDisabledDays();
  // const initDisabledDays = [{ before: new Date() }];
  const [disabledDays, setDisabledDays] = useState(initDisabledDays);
  const [footerTxt, setFooterTxt] = useState('test');

  const optionsRange = [
    {
      name: 'Wybierz okres',
      value: 'range',
      isDisabled: isTrialDay, //if tial day is selcted this is disabled
    },
    {
      name: 'Wybierz poszczególne dni',
      value: 'multiple',
    },
  ];

  const optionsWeekend = [
    {
      label: 'bez sobót',
      name: 'isSaturdayBlocked',
      isChecked: isSaturdayBlocked,
    },
    {
      label: 'bez niedziel',
      name: 'isSundayBlocked',
      isChecked: isSundayBlocked,
    },
  ];

  //unset sat and sun when mode is changed
  useEffect(() => {
    if (scheduleMode !== 'range') {
      //reset isSat and is isSun if it is no range
      dispatch({
        type: CART_ITEM_TOGGLE,
        payload: { name: 'isSaturdayBlocked', isChecked: false },
      });
      dispatch({
        type: CART_ITEM_TOGGLE,
        payload: { name: 'isSundayBlocked', isChecked: false },
      });
    }
    dispatch({ type: CART_ITEM_CLEAR_DAYS });
  }, [scheduleMode]);

  //blokowanie soboty lub niedzieli na kalendarzu
  useEffect(() => {
    let newDaysOfWeek = [];
    if (isSaturdayBlocked) newDaysOfWeek = [6];
    if (isSundayBlocked) newDaysOfWeek = [...newDaysOfWeek, 0];
    const newDisabledDays = [
      ...initDisabledDays,
      {
        daysOfWeek: newDaysOfWeek,
      },
    ];
    setDisabledDays(newDisabledDays);
    dispatch({ type: CART_ITEM_CLEAR_DAYS });
  }, [isSaturdayBlocked, isSundayBlocked]);

  const dayClickHandler = (day, modifiers) => {
    const { selected, disabled } = modifiers;
    if (!disabled) {
      dispatch({ type: CART_ITEM_SET_DAYS, payload: { day, selected } });
    }
  };

  const handleOnDayMouseEnter = (day) => {
    if (start && !end) {
      dispatch({ type: CART_ITEM_MOUSE_ENTER_DAY, payload: day });
    }
  };

  //change footer txt
  useEffect(() => {
    let txt = '';

    if (isTrialDay) {
      txt = 'Wybierz dzień dostawy zestawu próbnego';
    } else if (scheduleMode === 'multiple' && !deliveryDays.length) {
      txt = 'Wybierz pierwszy dzień dostawy';
    } else if (scheduleMode === 'multiple' && deliveryDays.length) {
      txt = 'Wybierz kolejny dzień dostawy';
    } else if (scheduleMode === 'range') {
      if (!start && !end) {
        txt = 'Wybierz początek dostaw';
      }
      if (start && !end) {
        txt = 'Wybierz koniec dostaw';
      }
      if (start && end) {
        txt = 'Możesz modyfikować harmonogram klikając poszczególne dni';
      }
    }
    setFooterTxt(txt);
  }, [isTrialDay, scheduleMode, deliveryDays, range]);

  return (
    <div>
      <Collapse in={calories ? true : false}>
        <>
          <CustomizedRadios
            label='Wybierz dni dostawy'
            optionList={optionsRange}
            value={scheduleMode}
            changeHandler={(newValue) =>
              dispatch({
                type: CART_ITEM_UPDATE_SCHEDULE,
                payload: newValue,
              })
            }
          />
          <Collapse in={scheduleMode === 'range' ? true : false}>
            <div style={{ marginTop: 16 }}>
              <CustomizedSwitch
                label='Opcje wyboru zakresu dat'
                optionList={optionsWeekend}
                updateState={(name, isChecked) =>
                  dispatch({
                    type: CART_ITEM_TOGGLE,
                    payload: { name, isChecked },
                  })
                }
              />
            </div>
          </Collapse>
          {/* <Box bgcolor='primary.main' p={2} my={4} borderRadius={8}>
            <Typography variant='subtitle1'>
              Od 30.04.22 do 03.05.22 odpoczywamy razem z Wami :)
            </Typography>
          </Box> */}
          <div className={classes.dayPicker}>
            <DayPicker
              locale='pl'
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              numberOfMonths={2}
              fromMonth={new Date()}
              selectedDays={deliveryDays}
              onDayClick={dayClickHandler}
              disabledDays={disabledDays}
              // modifiers={modifiers}
              modifiersStyles={modifiersStyles}
              onDayMouseEnter={handleOnDayMouseEnter}
              // showOutsideDays={false}
              // enableOutsideDaysClick={false}
            />
          </div>
          <div>
            <Typography variant='subtitle1' color='secondary'>
              {footerTxt}
            </Typography>
          </div>

          <Button onClick={() => dispatch({ type: CART_ITEM_CLEAR_DAYS })}>
            Wyczyść
          </Button>
        </>
      </Collapse>
    </div>
  );
};

export default ScheduleModule;
