import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function OrderBreadCrumb({ productName }) {
  const history = useHistory();
  const handleClick = (event) => {
    event.preventDefault();
    history.push('/sklep');
  };
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link color='inherit' onClick={handleClick}>
        Wszystkie diety pudełkowe
      </Link>
      <Typography color='textPrimary'>{productName}</Typography>
    </Breadcrumbs>
  );
}
