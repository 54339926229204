import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  noDietsText: {
    marginTop: theme.spacing(2),
  },
}));

const NoOrderInfo = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body1' color='secondary'>
        Brak aktywnych zamówień!
      </Typography>
      <Typography variant='body1' className={classes.noDietsText}>
        Zamów posiłki już teraz i ciesz się wyśmienitym smakiem!
      </Typography>
    </div>
  );
};

export default NoOrderInfo;
