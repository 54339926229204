import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import formatISO from 'date-fns/formatISO';
import { getProductBySlug } from '../actions/productActions';
import { getDailyDiscounts } from '../actions/optionActions';
import { updateCaloriesCart } from '../actions/cartItemActions';
import {
  CART_ITEM_INIT,
  CART_ITEM_SET_DAILY_DISCOUNT,
  CART_ITEM_TRIAL_TOGGLE,
  CART_ITEM_PRICE_UPDATE,
  CART_ITEM_ADD_QTY,
  CART_ITEM_SUBSTRACT_QTY,
  CART_ITEM_RESET,
} from '../constants/cartItemConstants';
import { CART_ADD_ITEM } from '../constants/cartConstants';
import CaloriesSelection from '../components/newOrder/CaloriesSelection';
import ScheduleModule from '../components/newOrder/ScheduleModule';
import CustomizedSwitch from '../components/layout/CustomizedSwitch';
import Summary from '../components/newOrder/Summary';
import Qty from '../components/newOrder/Qty';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Loader from '../components/layout/Loader';
import AppBarOrder from '../components/nav/AppBarOrder';
import OrderBreadCrumb from '../components/nav/OrderBreadCrumb';
import Typography from '@mui/material/Typography';
import { API_URL } from '../config';
import _ from 'lodash';
import { GAAddToCart, GAViewItem } from '../utils/GAEvents';

// const useStyles = makeStyles(() => ({
//   imgDiv: {
//     width: '80%',
//     margin: 'auto',
//   },
//   title: {
//     marginBottom: 16,
//     marginTop: 16,
//     fontSize: '2rem',
//   },
// }));

const NewOrderConfigScreen = ({ match, location }) => {
  const { productSlug } = match.params;
  const queryStr = queryString.parse(location.search);
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.productDetails);
  const { dailyDiscounts } = useSelector((state) => state.optionList);

  const { profile } = useSelector((state) => state.auth);
  const { cart } = useSelector((state) => state.cart);
  const { cartItem } = useSelector((state) => state);
  const {
    qty,
    calories,
    isTrialDay,
    dailyRate,
    dailyRateBase,
    dailyRateDiscount,
    deliveryDays,
    price,
    scheduleMode,
    isSaturdayBlocked,
    isSundayBlocked,
  } = cartItem;

  const nrTrialDays = profile?.nrTrialDays || 0;
  //trial day is only available if user dodnt use it before or it is not in current cart
  let isTrialDayAvailable = nrTrialDays === 0;

  if (cart.orderItems.length) {
    isTrialDayAvailable =
      isTrialDayAvailable &&
      cart.orderItems.reduce(
        (isTrial, item) => isTrial && !item.isTrialDay,
        true
      );
  }

  // const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    dispatch(getProductBySlug(productSlug));
    dispatch(getDailyDiscounts());
  }, [productSlug]);

  useEffect(() => {
    if (product) {
      GAViewItem(product);
    }
  }, [product]);

  //set reducer based on the query str
  useEffect(() => {
    if (_.isEmpty(queryStr)) {
      dispatch({ type: CART_ITEM_RESET });
    } else {
      let {
        calories,
        qty,
        scheduleMode,
        isSaturdayBlocked,
        isSundayBlocked,
        isTrialDay,
        selectedDays,
      } = queryStr;
      calories = calories ? parseInt(calories) : 0;
      qty = qty ? parseInt(qty) : 1;
      scheduleMode = scheduleMode ? scheduleMode : 'range';

      isSaturdayBlocked = isSaturdayBlocked === 'true';
      isSundayBlocked = isSundayBlocked === 'true';
      isTrialDay = isTrialDay === 'true';
      selectedDays = selectedDays ? selectedDays.split(',') : [];

      dispatch({
        type: CART_ITEM_INIT,
        payload: {
          calories,
          qty,
          scheduleMode,
          isSaturdayBlocked,
          isSundayBlocked,
          isTrialDay,
          deliveryDays: selectedDays.map((d) => new Date(d)),
        },
      });
    }
  }, []);

  //updates price when calories are changed on page load
  useEffect(() => {
    if (calories && product) dispatch(updateCaloriesCart(calories));
  }, [calories, product]);

  //set query str based on reducer
  useEffect(() => {
    const selectedDays = deliveryDays.length
      ? deliveryDays
          .map((d) => formatISO(d, { representation: 'date' }))
          .toString()
      : '';
    history.push(
      `/sklep/${productSlug}?calories=${calories}&qty=${qty}&scheduleMode=${scheduleMode}&isSaturdayBlocked=${isSaturdayBlocked}&isSundayBlocked=${isSundayBlocked}&isTrialDay=${isTrialDay}&selectedDays=${selectedDays}`
    );
  }, [
    productSlug,
    calories,
    qty,
    scheduleMode,
    isSaturdayBlocked,
    isSundayBlocked,
    isTrialDay,
    deliveryDays,
    history,
  ]);

  // Update price when duration or calories are changing
  useEffect(() => {
    dispatch({ type: CART_ITEM_PRICE_UPDATE });
  }, [dailyRateBase, dailyRateDiscount, deliveryDays, qty, isTrialDay]);

  useEffect(() => {
    let dailyDiscount = 0;
    if (dailyDiscounts.length) {
      dailyDiscount =
        dailyDiscounts?.find(
          (d) =>
            deliveryDays.length >= d.minNrDays &&
            deliveryDays.length <= d.maxNrDays
        )?.durationDiscount || 0;
    }

    dispatch({ type: CART_ITEM_SET_DAILY_DISCOUNT, payload: dailyDiscount });
  }, [dailyDiscounts, deliveryDays, isTrialDay]);

  const handleSubmit = () => {
    const updatedCartItem = {
      ...cartItem,
      product,
      name: product.name,
    };
    dispatch({ type: CART_ADD_ITEM, payload: updatedCartItem });
    dispatch({ type: CART_ITEM_RESET });
    GAAddToCart(updatedCartItem);
    history.push('/koszyk');
  };

  return !product ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <>
        <AppBarOrder />

        <Container>
          <Typography
            // align='center'
            variant='h2'
            component='h1'
            sx={{
              my: 3,
              // fontSize: '1rem'
            }}
            // className={classes.title}
          >
            Wybierz swój wariant dla {product.name}
          </Typography>
          <OrderBreadCrumb productName={product.name} />
          <Summary handleSubmit={handleSubmit} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box p={4} textAlign='center'>
                <div
                // className={classes.imgDiv}
                >
                  <img
                    alt={product.name}
                    width='80%'
                    src={`/diets/${product.photo}`}
                    // src={`${API_URL}/api/v1//uploads/${product.photo}`}
                  />
                </div>
                <Qty
                  qty={qty}
                  handleAddDiets={() => dispatch({ type: CART_ITEM_ADD_QTY })}
                  handleRemoveDiets={() =>
                    dispatch({ type: CART_ITEM_SUBSTRACT_QTY })
                  }
                />
                {isTrialDayAvailable && (
                  <div style={{ textAlign: 'center', marginTop: 24 }}>
                    <CustomizedSwitch
                      label='Zestaw próbny'
                      optionList={[
                        {
                          label:
                            'Pierwszy raz próbuję FitDiet i chcę zestaw próbny!',
                          name: 'isTrialDay',
                          isChecked: isTrialDay,
                        },
                      ]}
                      updateState={(isTrialDay, isChecked) =>
                        dispatch({
                          type: CART_ITEM_TRIAL_TOGGLE,
                          payload: isChecked,
                        })
                      }
                    />
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Box p={2} textAlign='center'>
                  <CaloriesSelection
                    productCalories={product.calories}
                    currentSelection={calories}
                    changeHandler={(newCalories) =>
                      dispatch(updateCaloriesCart(parseInt(newCalories)))
                    }
                  />
                </Box>
              </Box>
              {/* <Fade in={calories ? true : false}> */}
              <Box p={2} textAlign='center'>
                <ScheduleModule />
              </Box>
              {/* </Fade> */}
            </Grid>
          </Grid>
        </Container>
      </>
    </>
  );
};

export default NewOrderConfigScreen;
