import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Layout from '../components/layout/Layout';
import { getOrders } from '../actions/orderListAction';
import { makeStyles } from '@mui/styles';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';
import { ORDERS_CLEAR_ERROR } from '../constants/orderListConstants';
import format from 'date-fns/format';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead } from '@mui/material';
import AddDietButton from '../components/layout/AddDietButton';
import clsx from 'clsx';
import green from '@mui/material/colors/green';
import NoOrderInfo from '../components/order/NoOrderInfo';
import TitleScreen from '../components/layout/TitleScreen';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  unPaid: {
    color: theme.palette.secondary.main,
  },
  paid: {
    color: green[500],
  },
}));

const OrderHistoryScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.auth.profile);
  const { orders, loading, error } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getOrders(_id));
  }, [_id, dispatch]);

  return (
    <Layout>
      <Helmet>
        <title>Zamówienia</title>
      </Helmet>
      <Container>
        <TitleScreen title='Moje zamówienia' />
        {/* <div className={classes.titleContainer}>
          <Typography className={classes.title} variant='h5'>
            Moje zamówienia
          </Typography>
          <AddDietButton text='Zamów' />
        </div> */}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message
            message={error}
            handleClose={() => dispatch({ type: ORDERS_CLEAR_ERROR })}
          />
        ) : orders.length ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Data <br />
                    <Typography variant='inherit' color='textSecondary'>
                      Nr zam.
                    </Typography>
                  </TableCell>
                  {/* <TableCell>Nr zam.</TableCell> */}
                  <TableCell>Produkty</TableCell>
                  <TableCell align='right'>
                    Kwota [zł] <br />
                    <Typography variant='inherit' color='textSecondary'>
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((o) => (
                  <TableRow key={o._id}>
                    <TableCell>
                      {format(new Date(o.createdAt), 'dd.MM.yy')}
                      <br />
                      <Typography variant='inherit' color='textSecondary'>
                        #{o.no}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>{o.no}</TableCell> */}
                    <TableCell>
                      {o.orderItems.map((i) => (
                        <div key={i._id}>{`${i.name} ${i.calories}`}</div>
                      ))}
                    </TableCell>
                    <TableCell align='right'>
                      {o.totalPrice}
                      <div
                        className={clsx(
                          o.isPaid ? classes.paid : classes.unPaid
                        )}
                      >
                        {o.isPaid ? 'Opłacone' : 'Nieopłacone'}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoOrderInfo />
        )}
      </Container>
    </Layout>
  );
};

export default OrderHistoryScreen;
