import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import { USER_CLEAR_ERROR } from '../constants/userConstants';
import { AUTH_CLEAR_ERROR } from '../constants/authConstants';
import UserEdit from '../components/profile/UserEdit';

import Layout from '../components/layout/Layout';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import Container from '@mui/material/Container';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';
import _ from 'lodash';
import PasswordEdit from '../components/profile/PasswordEdit';
import { Helmet } from 'react-helmet-async';
import AddressCard from '../components/profile/AddressCard';
import InvoiceData from '../components/profile/InvoiceData';

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    maxWidth: 500,
    marginBottom: theme.spacing(2),
    margin: 'auto',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
    marginRight: theme.spacing(2),
  },
}));

const AccountInfoScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, error, message, user } = useSelector(
    (state) => state.userDetails
  );
  const {
    loading: authLoading,
    error: authError,
    message: authMessage,
  } = useSelector((state) => state.auth);
  const { _id, fName, sName, email, phone, addresses } = user || {};
  const [isUserEditOpen, setIsUserEditOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: AUTH_CLEAR_ERROR });
    if (!user) dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Profil</title>
      </Helmet>
      <Layout>
        {loading || authLoading ? (
          <Loader />
        ) : (
          <>
            {authError ||
              (authMessage && (
                <Message
                  type={authError ? 'error' : 'success'}
                  message={authError || authMessage}
                  handleClose={() => dispatch({ type: AUTH_CLEAR_ERROR })}
                />
              ))}
            {error ||
              (message && (
                <Message
                  type={error ? 'error' : 'success'}
                  message={error || message}
                  handleClose={() => dispatch({ type: USER_CLEAR_ERROR })}
                />
              ))}
            <Container>
              <Card className={classes.root}>
                <CardContent>
                  <div className={classes.titleContainer}>
                    <Avatar className={classes.avatar}>
                      <PersonIcon />
                    </Avatar>
                    <Typography variant='h5' color='textSecondary'>
                      Mój profil
                    </Typography>
                  </div>
                  <Typography variant='body1'>
                    {fName} {sName}
                  </Typography>
                  <Typography variant='body1'>{email}</Typography>
                  <Typography variant='body1'>{phone}</Typography>
                </CardContent>
                <CardActions>
                  <Button size='small' onClick={() => setIsUserEditOpen(true)}>
                    Zmień dane
                  </Button>
                  <Button size='small' onClick={() => setIsPasswordOpen(true)}>
                    Zmień hasło
                  </Button>
                </CardActions>
              </Card>
              <AddressCard addresses={addresses} />
              {/* <InvoiceData /> */}

              <UserEdit
                open={isUserEditOpen}
                onClose={() => setIsUserEditOpen(false)}
                userId={_id}
                fName={fName}
                sName={sName}
                email={email}
                phone={phone}
              />
              {isPasswordOpen && (
                <PasswordEdit
                  open={isPasswordOpen}
                  onClose={() => setIsPasswordOpen(false)}
                />
              )}
            </Container>
          </>
        )}
      </Layout>
    </>
  );
};

export default AccountInfoScreen;
