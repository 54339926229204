import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { CART_SET_ADDRESS } from '../../constants/cartConstants';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddressAdd from '../profile/AddressAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 24,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const AddressSelection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.cart.cart);
  const { addresses } = useSelector((state) => state.userDetails.user);

  // useEffect(() => {
  //   if (!addresses.length) dispatch({ type: CART_SET_ADDRESS, payload: null });
  // }, [addresses.length]);

  const handleChange = (e) => {
    const selectedAddress = addresses.find((a) => a._id === e.target.value);
    dispatch({ type: CART_SET_ADDRESS, payload: selectedAddress });
  };

  // if (!addresses.length) return 'Brak adresu';

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant='h5'>Adres dostawy</Typography>
      </div>
      {addresses.length ? (
        <FormControl fullWidth>
          <InputLabel>Wybierz adres</InputLabel>
          <Select
            value={address?._id}
            onChange={handleChange}
            name='address'
            color='secondary'
            // variant='outlined'
          >
            {addresses.map((a) => {
              return (
                <MenuItem key={a._id} value={a._id}>
                  {a.formattedAddress}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <AddressAdd />
      )}
    </div>
  );
};

export default AddressSelection;
