import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@mui/styles';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import MyMenu from '../dietDetails/MyMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   maxWidth: 345,
    },
    media: {
      width: '50%!important',
      margin: 'auto',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    // avatar: {
    //   backgroundColor: red[500],
    // },
    myMenuTitle: {
      width: '100%',
      textAlign: 'center',
    },
  })
);
const formatDate = (dateStr) => {
  let formatedDate = '';
  const dateObj = new Date(dateStr);
  if (isValid(dateObj)) {
    formatedDate = format(dateObj, 'd.MM.yyyy');
  }
  return formatedDate;
};

export default function DietCard({
  dietName,
  photo,
  startAt,
  endAt,
  orderNo,
  meals,
  orderId,
  dietId,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let history = useHistory();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton
            aria-label='więcej informacji'
            onClick={() =>
              history.push(`/dieta?orderId=${orderId}&itemId=${dietId}`)
            }
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={dietName}
        subheader={`Zamówienie nr ${orderNo}`}
      />
      <CardMedia
        component='img'
        height='50%'
        className={classes.media}
        image={`/diets/${photo}`}
        // image={`${API_URL}/api/v1/uploads/${photo}`}
        title={dietName}
      />
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p'>
          {`Początek: ${formatDate(startAt)}`}
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          {`Koniec: ${formatDate(endAt)}`}
        </Typography>
      </CardContent>
      {meals && (
        <>
          <CardActions disableSpacing>
            <div className={classes.myMenuTitle}>
              <Typography variant='body2' color='primary'>
                MOJE DZISIEJSZE MENU
              </Typography>
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <MyMenu meals={meals} />
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
}
