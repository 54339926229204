import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProductList } from '../actions/productActions';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import Layout from '../components/layout/Layout';
import AppBarOrder from '../components/nav/AppBarOrder';
import { API_URL } from '../config';
import { CART_ITEM_RESET } from '../constants/cartItemConstants';
import { PRODUCT_DETAILS_RESET } from '../constants/productConstants';

const useStyles = makeStyles({
  // root: { height: 300, alignContent: 'center' },
  // media: { width: '80%', margin: 'auto' },
  // titlePage: {
  //   marginBottom: 16,
  //   marginTop: 16,
  //   fontSize: '2rem',
  // },
  titleProduct: {
    fontSize: '1.2rem',
  },
});

const ProductCard = ({
  productPhotoName = 'photo_Testowa dieta_5f4e04e36c6d0f305167f0d2.png',
  name = 'Dieta',
  slug,
}) => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <Card
          variant='outlined'
          className={classes.root}
          // sx={{ height: 250 }}
        >
          <CardActionArea onClick={() => history.push(`/sklep/${slug}`)}>
            <CardMedia
              sx={{
                width: '80%',
                margin: 'auto',
                padding: 2,
              }}
              component='img'
              // height='150'
              // width='150'
              // className={classes.media}
              // image={`${API_URL}/api/v1/uploads/${productPhotoName}`}
              image={`/diets/${productPhotoName}`}
              title={name}
            ></CardMedia>
            <CardContent>
              <Typography
                align='center'
                // gutterBottom
                variant='h5'
                className={classes.titleProduct}
              >
                {name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

const NewOrderProductScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { products, loading, error } = useSelector(
    (state) => state.productList
  );

  useEffect(() => {
    dispatch(
      getProductList({
        // filter: `category=${category._id}`,
        filter: `category=5ed774b42e6b44f02eae5116`,
        select: `name,photo,slug, active`,
        sort: 'displayOrder',
      })
    );
    dispatch({ type: CART_ITEM_RESET });
    dispatch({ type: PRODUCT_DETAILS_RESET });
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Sklep</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message message={error} />
      ) : (
        <>
          <AppBarOrder />

          {/* // <Layout > */}
          <Container>
            {/* <OrderStepper /> */}
            <Typography align='center' variant='h1' sx={{ my: 10 }}>
              Diety pudełkowe
            </Typography>
            <Grid container spacing={3} justify='center'>
              {products &&
                products.length > 0 &&
                products.map(
                  (p) =>
                    p.category.slug === 'diety' &&
                    p.active && (
                      <ProductCard
                        key={p._id}
                        name={p.name}
                        slug={p.slug}
                        productPhotoName={p.photo}
                      />
                    )
                )}
            </Grid>
          </Container>
        </>
        // {/* // </Layout> */}
      )}
    </div>
  );
};

export default NewOrderProductScreen;
