import api from '../utils/api';
import _ from 'lodash';

import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_ADDRESS_NEW_REQUEST,
  USER_ADDRESS_NEW_SUCCESS,
  USER_ADDRESS_NEW_FAIL,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_SUCCESS,
  USER_ADDRESS_UPDATE_FAIL,
} from '../constants/userConstants';

export const getUserDetails = () => async (dispatch, getState) => {
  const {
    auth: { profile },
  } = getState();
  try {
    dispatch({ type: GET_USER_DETAILS_REQUEST });
    const res = await api.get(`users/${profile._id}/`);
    dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: 'Upss... w tej chwili nie możemy wyświetlić Twojego profilu :(',
    });
  }
};

//Update user
export const updateUser = (updatedUser) => async (dispatch, getState) => {
  const {
    auth: { profile },
  } = getState();
  try {
    dispatch({ type: USER_UPDATE_REQUEST });
    const { data } = await api.put(`/users/${profile._id}`, updatedUser);
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: { user: data.data, message: 'Twoje dane zostały zmienione' },
    });
  } catch (err) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        'Upss... Coś poszło nie tak i w tej chwili nie możemy zmienić Twoich danych. Sprawdź czy wpisałeś poprawne informacje!',
    });
  }
};

export const addAddress = (userId, newAddress) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: USER_ADDRESS_NEW_REQUEST });
    const { data } = await api.post(`/users/${userId}/addresses`, newAddress);
    dispatch({
      type: USER_ADDRESS_NEW_SUCCESS,
      payload: { user: data.data, message: 'Adres został dodany!' },
    });
  } catch (err) {
    dispatch({
      type: USER_ADDRESS_NEW_FAIL,
      payload: 'Ups. Problem z dodaniem adresu. Spróbuj jeszcze raz',
    });
  }
};

//Update user address
export const updateAddress = (userId, updatedAddress) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: USER_ADDRESS_UPDATE_REQUEST });
    const { data } = await api.put(
      `/users/${userId}/addresses/${updatedAddress._id}`,
      updatedAddress
    );
    dispatch({
      type: USER_ADDRESS_UPDATE_SUCCESS,
      payload: { user: data.data, message: 'Adres został zmieniony!' },
    });
  } catch (err) {
    dispatch({
      type: USER_ADDRESS_UPDATE_FAIL,
      payload: 'Ups. Problem ze zmianą adresu. Spróbuj jeszcze raz',
    });
  }
};

export const deleteAddress = (addressId) => (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();
  if (user.addresses.length < 2) return;
  const updatedUser = {
    ...user,
    addresses: user.addresses.filter((a) => a._id !== addressId),
  };

  dispatch(updateUser(updatedUser));
};
