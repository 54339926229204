import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, Theme, createStyles } from '@mui/styles';
import TopBarInfo from './TopBarInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
  })
);

export default function AppBarOrder() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='sticky' className={classes.appBar} color='default'>
        <Toolbar>
          <TopBarInfo />
        </Toolbar>
      </AppBar>
    </div>
  );
}
