import React from 'react';
import Typography from '@mui/material/Typography';

const TitleScreen = ({ title, align = 'left' }) => {
  return (
    <Typography
      sx={{
        my: 1,
        fontSize: '2rem',
        fontWeight: 300,
      }}
      variant='h3'
      component='h1'
      // className={classes.titlePage}
      align={align}
    >
      {title}
    </Typography>
  );
};

export default TitleScreen;
