import {
  DIET_LIST_REQUEST,
  DIET_LIST_SUCCESS,
  DIET_LIST_FAIL,
  DIET_LIST_CLEAR_ERROR,
} from '../constants/dietListConstants';

import produce from 'immer';

const initOrder = {
  diets: [],
  loading: null,
  error: null,
};

const dietListReducer = produce((draft, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case DIET_LIST_REQUEST:
      draft.loading = true;
      return;
    case DIET_LIST_SUCCESS:
      draft.loading = false;
      draft.diets = payload;
      return;
    case DIET_LIST_FAIL:
      draft.loading = false;
      draft.error = payload;
      return;
    case DIET_LIST_CLEAR_ERROR:
      draft.error = null;
      return;
  }
}, initOrder);

export default dietListReducer;
