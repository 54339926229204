import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { LOGOUT } from '../../constants/authConstants';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from './logo_fitdiet.png';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    maxWidth: 90,
    marginRight: '10px',
  },
  loginArea: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function TopBarInfo({ handleDrawerToggle }) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, profile } = useSelector((state) => state.auth);
  const { orderItems } = useSelector((state) => state.cart.cart);
  const shoppingItemCount = orderItems.length;

  return (
    <div className={classes.root}>
      <div>
        <a href='https://www.fitdiet.pl'>
          <img src={logo} alt='Fitdiet logo' className={classes.logo} />
        </a>
      </div>
      <div>
        <div className={classes.loginArea}>
          {isAuthenticated && profile && (
            <Typography variant='subtitle2'>{`Miło Cię widzieć, ${profile.fName}!`}</Typography>
          )}
          <Tooltip title='Przejdź do koszyka'>
            <IconButton onClick={() => history.push('/koszyk')}>
              <Badge color='secondary' badgeContent={shoppingItemCount}>
                <ShoppingBasketIcon color='primary' />
              </Badge>
            </IconButton>
          </Tooltip>

          {isAuthenticated && profile ? (
            <>
              <Tooltip title='Przejdź do konta'>
                <IconButton onClick={() => history.push('/')} color='primary'>
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Wyloguj się'>
                <IconButton
                  onClick={() => dispatch({ type: LOGOUT })}
                  color='secondary'
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title='Zaloguj się'>
              <Link to='/logowanie'>
                <IconButton color='secondary'>
                  <LockSharpIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
