import api from '../utils/api';

import {
  ORDER_ITEM_REQUEST,
  ORDER_ITEM_SUCCESS,
  ORDER_ITEM_FAIL,
} from '../constants/orderItemConstants';

export const getOrderItem = (orderId, orderItemId) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_ITEM_REQUEST });
    const res = await api.get(`orders/${orderId}/order-items/${orderItemId}`);
    dispatch({ type: ORDER_ITEM_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: ORDER_ITEM_FAIL,
      payload:
        'Upss... w tej chwili nie możemy wyświetlić Twojego zamówienia :(',
    });
  }
};
