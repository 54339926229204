import api from '../utils/api';
// import { setAlert } from './alert';
import {
  AUTH_REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_PASSWORD_UPDATE_REQUEST,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_PASSWORD_UPDATE_FAIL,
  FORGOT_PASSWORD_MAIL_REQUEST,
  FORGOT_PASSWORD_MAIL_SUCCCESS,
  FORGOT_PASSWORD_MAIL_ERROR,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
} from '../constants/authConstants';
import { loadCart } from './cartActions';
import { API_URL } from '../config';

// Load User
export const loadUser = () => async (dispatch, getState) => {
  const {
    cart: {
      cart: { orderItems },
    },
  } = getState();
  try {
    dispatch({
      type: AUTH_PROFILE_REQUEST,
    });
    const res = await api.get(`${API_URL}/auth/me`);
    //if no current cart load user cart from db
    if (!orderItems.length) dispatch(loadCart(res.data.data._id));
    dispatch({
      type: AUTH_PROFILE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
    const message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    localStorage.removeItem('token');
    dispatch({
      type: AUTH_PROFILE_FAIL,
      payload: message,
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_REGISTER_REQUEST,
    });

    const res = await api.post(`${API_URL}/auth/register`, formData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    // const errors = err.response.data.errors;

    // if (errors) {
    //   // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    // }

    dispatch({
      type: REGISTER_FAIL,
      payload: err.response.data.error,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  console.log('login');
  console.log('API_URL', API_URL);
  const body = { email, password };

  try {
    dispatch({ type: LOGIN_REQUEST });
    const res = await api.post(`${API_URL}/auth/login`, body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.token,
    });

    dispatch(loadUser());
  } catch (err) {
    const error = 'Sprawdź czy dane logowania są poprawne';
    // const error = err.response.data.error;
    // if (error) {
    // dispatch(setAlert(error, 'error'));
    // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    // }

    dispatch({
      type: LOGIN_FAIL,
      payload: error,
    });
  }
};
export const generateForgotPasswordMail = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_MAIL_REQUEST });
    const res = await api.post(`${API_URL}/auth/forgotpassword`, { email });
    dispatch({ type: FORGOT_PASSWORD_MAIL_SUCCCESS });
  } catch (error) {
    let errMsg = 'Upss... w tej chwili nie możemy wysłać maila :(';
    if (error.response.status === 404)
      errMsg = 'Błędny email. Wpisz poprawny adres.';
    dispatch({
      type: FORGOT_PASSWORD_MAIL_ERROR,
      payload: errMsg,
    });
  }
};

export const resetPassword = (password, resetToken) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_RESET_PASSWORD_REQUEST });
    const res = await api.put(`${API_URL}/auth/resetpassword/${resetToken}`, {
      password,
    });
    dispatch({ type: AUTH_RESET_PASSWORD_SUCCESS });
  } catch (error) {
    let errorMsg = 'Upss... w tej chwili nie możemy zmienić Twojego hasła :(';

    if (error.response.data.error === 'Invalid token')
      errorMsg =
        'Twój token utracił ważność bądź jest niepoprawny. Wygeneruj kolejny jeśli chcesz zmienić hasło';

    dispatch({
      type: AUTH_RESET_PASSWORD_FAIL,
      payload: errorMsg,
    });
  }
};

export const updatePassword =
  (currentPassword, newPassword) => async (dispatch) => {
    try {
      dispatch({ type: AUTH_PASSWORD_UPDATE_REQUEST });
      const res = await api.put(`${API_URL}/auth/updatepassword/`, {
        currentPassword,
        newPassword,
      });
      dispatch({
        type: AUTH_PASSWORD_UPDATE_SUCCESS,
        payload: { token: res.data.token, message: 'Hasło zmienione!' },
      });
    } catch (error) {
      dispatch({
        type: AUTH_PASSWORD_UPDATE_FAIL,
        payload:
          'Błąd! Nie możemy zmienić Twojego hasła. Twoje obecne hasło jest inne!',
      });
    }
  };
