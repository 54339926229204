import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CART_REMOVE_ITEM,
  CART_SET_ERROR,
} from '../../constants/cartConstants';
import { saveCart } from '../../actions/cartActions';

import ProductCard from './ProductCard';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddDietButton from '../layout/AddDietButton';
import { GARemoveFromCart } from '../../utils/GAEvents';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  // titlePage: {
  //   marginBottom: '2rem',
  //   marginTop: '2rem',
  //   fontSize: '2rem',
  // },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productCard: {
    marginBottom: theme.spacing(2),
  },
}));

const Cart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { shouldSave, cart } = useSelector((state) => state.cart);
  const { orderItems } = cart;

  useEffect(() => {
    if (orderItems.length === 0) {
      dispatch({
        type: CART_SET_ERROR,
        payload: 'Brak produktów w koszyku. Dodaj coś!',
      });
    }
  }, [dispatch, orderItems]);

  useEffect(() => {
    if (shouldSave) dispatch(saveCart());
  }, [dispatch, shouldSave]);

  const handleRemoveItem = (id) => {
    GARemoveFromCart(orderItems[id]);
    dispatch({ type: CART_REMOVE_ITEM, payload: id });
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant='h2' component='h1' sx={{ my: 4 }}>
          Koszyk
        </Typography>
        <AddDietButton />
      </div>
      <Grid container>
        <Grid xs={12} md={12} item>
          {orderItems.map((cartItem, idx) => (
            <div key={idx} className={classes.productCard}>
              <ProductCard
                cartItem={cartItem}
                idx={idx}
                remove={handleRemoveItem}
              />
            </div>
          ))}
        </Grid>
      </Grid>
      {/* )} */}
    </div>
  );
};

export default Cart;
