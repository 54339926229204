import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import NewOrderProductScreen from './screens/NewOrderProductScreen';
import NewOrderConfigScreen from './screens/NewOrderConfigScreen';
import LoginScreen from './screens/LoginScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import CartScreen from './screens/CartScreen';
import NewOrderScreen from './screens/NewOrderScreen';
import OrderConfirmationScreen from './screens/OrderConfirmationScreen';
import RegisterScreen from './screens/RegisterScreen';
import DietListScreen from './screens/DietListScreen';
import DietScreen from './screens/DietScreen';
import ProfileScreen from './screens/ProfileScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import ReturningUserInfoScreen from './screens/ReturningUserInfoScreen';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/sklep' component={NewOrderProductScreen} />
        <Route
          exact
          path='/sklep/:productSlug'
          component={NewOrderConfigScreen}
        />
        <Route exact path='/koszyk' component={CartScreen} />
        <Route exact path='/zamow' component={NewOrderScreen} />

        <Route exact path='/logowanie' component={LoginScreen} />
        <Route exact path='/rejestracja' component={RegisterScreen} />
        <Route exact path='/info' component={ReturningUserInfoScreen} />

        <Route
          exact
          path='/odzyskiwanie-hasla'
          component={ForgotPasswordScreen}
        />
        <Route
          path='/reset-hasla/:resettoken'
          exact
          component={ResetPasswordScreen}
        />
        <PrivateRoute exact path='/' component={DietListScreen} />
        <PrivateRoute
          exact
          path='/potwierdzenie-zamowienia/:orderId'
          component={OrderConfirmationScreen}
        />
        <PrivateRoute exact path='/historia' component={OrderHistoryScreen} />
        <PrivateRoute exact path='/dieta' component={DietScreen} />
        <PrivateRoute exact path='/profil' component={ProfileScreen} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
