// import { Paper, Typography } from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import format from 'date-fns/format';
import { pl } from 'date-fns/locale';
import MyMenu from './MyMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
  },
  mealItem: {
    marginBottom: theme.spacing(2),
  },
}));

const DayInfo = ({ deliveredAt, status, dietName, street, city, meals }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card
        className={classes.root}
        //   className={classes.root}
      >
        <CardContent>
          <Typography color='textSecondary' gutterBottom>
            {format(deliveredAt, 'cccc, dd LLLL', { locale: pl })}
          </Typography>
          <Typography variant='h5' component='h2'>
            {dietName}
          </Typography>
          <Typography color='textSecondary' gutterBottom>
            {status}
          </Typography>
          <Typography className={classes.address} color='textSecondary'>
            Adres dostawy
          </Typography>
          <Typography variant='body2' component='p'>
            {street} <br />
            {city}
          </Typography>
          <hr />
          <Typography
            //   className={classes.pos}
            color='textSecondary'
          >
            Moje menu
          </Typography>
          <MyMenu meals={meals} />
          {/* {meals[0].mealNameWWW ? (
            meals.map((m) => (
              <div className={classes.mealItem}>
                <Typography variant='body2' component='p' color='secondary'>
                  {m.mealLabel.toUpperCase()} <br />
                </Typography>
                <Typography variant='body2' component='p'>
                  {m.mealNameWWW}
                </Typography>
              </div>
            ))
          ) : (
            <Typography variant='body2' component='p'>
              Wkrótce będzie dostępne
            </Typography>
          )} */}
        </CardContent>
        {/* <CardActions>
          <Button size='small'>Learn More</Button>
        </CardActions> */}
      </Card>
    </div>
  );
};

export default DayInfo;
