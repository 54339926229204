import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateForgotPasswordMail } from '../actions/authActions';
import {
  AUTH_CLEAR_ERROR,
  FORGOT_PASSWORD_MAIL_ERROR,
} from '../constants/authConstants';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Loader from '../components/layout/Loader';
import { Link, Redirect } from 'react-router-dom';
import Message from '../components/layout/Message';
import AppBarOrder from '../components/nav/AppBarOrder';
import ReturningUserInfo from '../components/ReturningUserInfo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const { loading, forgotPasswordMailSent, error } = useSelector(
    (state) => state.auth
  );
  const classes = useStyles();
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail('');
  }, [forgotPasswordMailSent]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const regEmail =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (!email || !email.match(regEmail)) {
      setEmail('');
      dispatch({
        type: FORGOT_PASSWORD_MAIL_ERROR,
        payload: 'Błędny email. Wpisz poprawny adres.',
      });
      return;
    }
    dispatch(generateForgotPasswordMail(email.toLowerCase()));
  };

  const handleCloseMessage = () => dispatch({ type: AUTH_CLEAR_ERROR });

  return (
    <div>
      <AppBarOrder />
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && (
            <Message message={error} handleClose={handleCloseMessage} />
          )}
          {forgotPasswordMailSent && (
            <Message
              message={'Email wysłany sprawdź swoją skrzynkę pocztową'}
              type='success'
              handleClose={handleCloseMessage}
            />
          )}
          <ReturningUserInfo />
          <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Wpisz adres
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Adres email'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  onChange={handleChange}
                  value={email}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  Wyślij link do resetu hasła
                </Button>
              </form>
            </div>
            <div>
              <Link to='/logowanie'>Logowanie</Link>
              <Link to='/rejestracja' style={{ float: 'right' }}>
                Rejestracja
              </Link>
            </div>
          </Container>
        </>
      )}
    </div>
  );
}
