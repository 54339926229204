import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const Loader = ({ open = true }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // display: 'flex',
      // justifyContent: 'center',
      // margin: theme.spacing(3),
      textAlign: 'center',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
