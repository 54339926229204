import api from '../utils/api';

import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
} from '../constants/orderListConstants';

export const getOrders = (userId) => async (dispatch) => {
  try {
    dispatch({ type: ORDERS_REQUEST });
    const res = await api.get(`users/${userId}/orders/`);
    dispatch({ type: ORDERS_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: ORDERS_FAIL,
      payload: 'Upss... w tej chwili nie możemy wyświetlić Twoich zamówień :(',
    });
  }
};
