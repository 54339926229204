import React, { useState } from 'react';

import AddressEdit from './AddressEdit/AddressEdit';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 'fit-content',
    margin: 'auto',
  },
}));

const AddressAdd = () => {
  const classes = useStyles();
  const [isAddressNewOpen, setIsAddressNewOpen] = useState(false);
  return (
    <div>
      <div className={classes.button}>
        <Button
          variant='outlined'
          size='large'
          startIcon={<AddIcon color='secondary' />}
          onClick={() => setIsAddressNewOpen(true)}
        >
          Dodaj adres
        </Button>
      </div>
      {isAddressNewOpen && (
        <AddressEdit
          open={isAddressNewOpen}
          onClose={() => setIsAddressNewOpen(false)}
          isNew={true}
        />
      )}
    </div>
  );
};

export default AddressAdd;
