import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_CLEAR_ERROR,
  USER_ADDRESS_NEW_REQUEST,
  USER_ADDRESS_NEW_SUCCESS,
  USER_ADDRESS_NEW_FAIL,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_SUCCESS,
  USER_ADDRESS_UPDATE_FAIL,
  CLEAR_USER,
} from '../constants/userConstants';

import produce from 'immer';

const init = {
  user: null,
  // user: {},
  loading: false,
  error: null,
  message: null,
};

const userDetailsReducer = produce((draft, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_USER_DETAILS_REQUEST:
    case USER_UPDATE_REQUEST:
    case USER_ADDRESS_NEW_REQUEST:
    case USER_ADDRESS_UPDATE_REQUEST:
      draft.error = null;
      draft.loading = true;
      return;
    case GET_USER_DETAILS_SUCCESS:
      draft.loading = false;
      draft.user = payload;
      return;
    case USER_UPDATE_SUCCESS:
    case USER_ADDRESS_NEW_SUCCESS:
    case USER_ADDRESS_UPDATE_SUCCESS:
      draft.loading = false;
      draft.user = payload.user;
      draft.message = payload.message;
      return;
    // case USER_ADDRESS_NEW_SUCCESS:
    // case USER_ADDRESS_UPDATE_SUCCESS:
    // const { newAddress, message } = payload;
    // const idx = draft.user.addresses.findIndex(
    //   (a) => a._id === newAddress._id
    // );
    // draft.user.addresses[idx] = newAddress;
    // draft.loading = false;
    // draft.message = message;
    // return;

    case GET_USER_DETAILS_FAIL:
    case USER_UPDATE_FAIL:
    case USER_ADDRESS_NEW_FAIL:
    case USER_ADDRESS_UPDATE_FAIL:
      draft.loading = false;
      draft.error = payload;
      return;
    case USER_CLEAR_ERROR:
      draft.error = null;
      draft.message = null;
      return;
  }
}, init);

export default userDetailsReducer;
