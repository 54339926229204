import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: '#82CDDD',
    // background: 'white',
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2),
    color: 'white',
    zIndex: 1,
    marginTop: theme.spacing(2),
    marginBotton: theme.spacing(2),
    borderRadius: 8,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Summary = ({ handleSubmit }) => {
  const {
    name: productName,
    deliveryDays,
    dailyRateBase,
    dailyRate,
    dailyRateDiscount,
    priceBase,
    price,
    qty,
  } = useSelector((state) => state.cartItem);

  const classes = useStyles();
  const nrDays = deliveryDays.length;

  let priceStr = '';
  priceStr = qty > 1 && `${qty} diety x `;
  priceStr += nrDays;
  priceStr += nrDays > 1 ? ' dni x ' : ' dzień x ';
  priceStr += `${dailyRateBase} zł = ${priceBase} zł`;

  const showPromoInfo = (dailyRateDiscount) => {
    const nrDaysToDiscount = 10 - nrDays;
    const descriptionTxt = dailyRateDiscount
      ? ` Świetnie!!! Otrzymasz dodatkowy rabat ${
          dailyRateDiscount * nrDays * qty
        } zł`
      : '';
    if (descriptionTxt) {
      return <Typography variant='subtitle1'>{descriptionTxt}</Typography>;
    } else
      return (
        <Typography variant='subtitle1'>
          Wybierz jeszcze {nrDaysToDiscount}
          {nrDaysToDiscount === 1 ? ' dzień' : ' dni'}, aby otrzymać rabat
        </Typography>
      );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Typography variant='h5' component='h1' align='left'>
            {productName}
          </Typography>
          {/* <Fade in={price ? true : false}> */}
          <div>
            <Typography variant='subtitle1' align='left'>
              {priceBase ? priceStr : <em>Wybierz wszystkie opcje</em>}
            </Typography>
            {showPromoInfo(dailyRateDiscount)}
          </div>
          {/* </Fade> */}
        </div>
        <div className={classes.button}>
          <Button
            variant='contained'
            color='secondary'
            disabled={!price}
            onClick={handleSubmit}
          >
            Dodaj
          </Button>
        </div>
      </div>
      {/* <hr /> */}
    </div>
  );
};

export default Summary;
