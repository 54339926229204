//Functions to send events to Google Analytics 4

import TagManager from 'react-gtm-module';

export const GAPurchase = (order, email) => {
  //check if transaction id is in local storage to prevent multiple fire of the event
  const transactionIds =
    JSON.parse(localStorage.getItem('transactionIds')) || [];
  const idx = transactionIds.indexOf(order.no);
  if (idx === -1) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          email,
          transaction_id: order.no,
          value: order.totalPrice,
          // shipping: order.shippingPrice,
          currency: 'PLN',
          items: order.orderItems.map((i) => ({
            item_name: i.name,
            // item_id: i.name,
            currency: 'PLN',
            price: i.price / i.nrDays,
            // price: i.price,
            item_variant: i.calories.toString(),
            quantity: i.qty * i.nrDays,
          })),
        },
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    transactionIds.push(tagManagerArgs.dataLayer.ecommerce.transaction_id);
    localStorage.setItem('transactionIds', JSON.stringify(transactionIds));
  }
};

export const GAAddToCart = (cartItem) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'add_to_cart',
      ecommerce: {
        currency: 'PLN',
        items: [
          {
            item_name: cartItem.name,
            quantity: cartItem.qty * cartItem.deliveryDays.length,
            item_variant: cartItem.calories.toString(),
            price: cartItem.dailyRate,
            // price: cartItem.price,
            currency: 'PLN',
          },
        ],
        value: cartItem.price,
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const GARemoveFromCart = (cartItem) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'PLN',
        items: [
          {
            item_name: cartItem.name,
            quantity: cartItem.qty * cartItem.deliveryDays.length,
            item_variant: cartItem.calories.toString(),
            price: cartItem.dailyRate,
            // price: cartItem.price,
            currency: 'PLN',
          },
        ],
        value: cartItem.price,
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const GAViewItem = (product) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        // currency: 'PLN',
        items: [
          {
            item_name: product.name,
            // item_variant: cartItem.calories,
            // price: cartItem.price,
            // currency: 'PLN',
          },
        ],
        // value: cartItem.price,
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
