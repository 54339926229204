import {
  AUTH_REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_PASSWORD_UPDATE_REQUEST,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_PASSWORD_UPDATE_FAIL,
  LOGOUT,
  FORGOT_PASSWORD_MAIL_REQUEST,
  FORGOT_PASSWORD_MAIL_SUCCCESS,
  FORGOT_PASSWORD_MAIL_ERROR,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
  ACCOUNT_DELETED,
  AUTH_CLEAR_ERROR,
} from '../constants/authConstants';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: false,
  profile: null,
  error: null,
  message: null,
  forgotPasswordMailSent: false,
  isPasswordChanged: false,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
    case AUTH_PROFILE_REQUEST:
    case AUTH_REGISTER_REQUEST:
    case AUTH_RESET_PASSWORD_REQUEST:
    case FORGOT_PASSWORD_MAIL_REQUEST:
    case AUTH_PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        profile: payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
        loading: false,
      };

    case AUTH_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        message: payload.message,
      };

    case ACCOUNT_DELETED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        profile: null,
      };

    case FORGOT_PASSWORD_MAIL_SUCCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        forgotPasswordMailSent: true,
      };

    case AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isPasswordChanged: true,
        message: 'Twoje hasło zostało zmienione!',
      };

    case AUTH_PROFILE_FAIL:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        profile: null,
      };

    case REGISTER_FAIL:
    case AUTH_RESET_PASSWORD_FAIL:
    case FORGOT_PASSWORD_MAIL_ERROR:
    case LOGIN_FAIL:
    case AUTH_PASSWORD_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case AUTH_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        message: null,
        forgotPasswordMailSent: false,
        isPasswordChanged: false,
      };

    default:
      return state;
  }
}
