import React from 'react';
import { getLocationDetails } from '../../../utils/userApi';
import { useQuery } from 'react-query';
import { useFormikContext, Field, FormikState } from 'formik';
import { makeStyles } from '@mui/styles';
import { TextField } from 'formik-material-ui';
import MenuItem from '@mui/material/MenuItem';
import { useStylesField } from './styles';

interface Props {}

const HoursField = (props: Props) => {
  const { values }: FormikState<AddressData> = useFormikContext();
  const locationDetailsQuery = useQuery(
    ['locationDetails', values.location],
    () => getLocationDetails(values.location)
  );

  // let hoursConverted = [];
  // if (locationDetailsQuery.data?.toTime) {
  //   hoursConverted = locationDetailsQuery.data.toTime;
  //   // hoursConverted = locationDetailsQuery.data.toTime.map((t: string) =>
  //   //   t.substring(0, 4)
  //   // );
  // }

  const classes = useStylesField();
  return (
    <Field
      component={TextField}
      select
      label='Dostawa do godz.'
      name='toTime'
      fullWidth
      variant='outlined'
      className={classes.field}
    >
      {locationDetailsQuery.data?.toTime ? (
        locationDetailsQuery.data?.toTime.map((h: string) => (
          <MenuItem key={h} value={h}>
            {h}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>Wybierz najpierw miasto</MenuItem>
      )}
    </Field>
  );
};

export default HoursField;
