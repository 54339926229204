import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { AUTH_CLEAR_ERROR } from '../constants/authConstants';
import { OPTION_DELIVERY_ZONE_RESET } from '../constants/optionConstants';
import { register } from '../actions/authActions';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Message from '../components/layout/Message';
import { useHistory } from 'react-router';
import AppBarOrder from '../components/nav/AppBarOrder';
import { Helmet } from 'react-helmet-async';
import ReturningUserInfo from '../components/ReturningUserInfo';

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch({ type: OPTION_DELIVERY_ZONE_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) history.push('/profil');
  }, [isAuthenticated]);

  return <>
    <Helmet>
      <title>Rejestracja</title>
    </Helmet>
    <AppBarOrder />
    <ReturningUserInfo />
    <Container>
      {error && (
        <Message
          message={error}
          handleClose={() => dispatch({ type: AUTH_CLEAR_ERROR })}
        />
      )}
      <Formik
        initialValues={{
          fName: '',
          sName: '',
          email: '',
          password: '',
          rePassword: '',
          phone: '',
        }}
        validationSchema={Yup.object({
          fName: Yup.string().required('Proszę podaj swoje imię'),
          sName: Yup.string().required('Proszę podaj swoje nazwisko'),
          email: Yup.string()
            .email('Błędny email')
            .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, {
              message: 'Podaj prawidłowy email',
            })
            .required('Proszę podaj swój email'),
          phone: Yup.string()
            .matches(/^(?:\(?\?)?(?:[-\.\(\)\s]*(\d)){9}\)?$/, {
              message: 'Podaj prawidłowy telefon w formacie xxx-xxx-xxx',
            })
            .required('Proszę podaj swój nr telefonu'),
          password: Yup.string()
            .required('Proszę uzupełnij hasło')
            .min(6, 'Hasło musi mieć co najmniej 6 znaków'),
          rePassword: Yup.string().required('Proszę wpisz ponownie hasło'),
        })}
        validate={(values) => {
          const errors = {};
          if (values.password !== values.rePassword)
            errors.rePassword = 'Hasła są rózne';
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const newUser = {
            ...values,
          };
          dispatch(register(newUser));
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mt={5} mb={3}>
              <Typography variant='h5'>Twoje dane</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='fName'
                  type='text'
                  label='Imię'
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='sName'
                  type='text'
                  label='Nazwisko'
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='email'
                  type='email'
                  label='Email'
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='phone'
                  type='text'
                  label='Telefon'
                  fullWidth
                  variant='outlined'
                  placeholder='XXX-XXX-XXX'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='password'
                  type='password'
                  label='Hasło'
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  name='rePassword'
                  type='password'
                  label='Powtórz hasło'
                  fullWidth
                  variant='outlined'
                />
              </Grid>
            </Grid>

            <Box mt={5}>
              {isSubmitting && (
                <LinearProgress style={{ marginBottom: 12 }} />
              )}
              <Button
                style={{ float: 'right' }}
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Zapisz
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Link to='/logowanie'>Logowanie</Link>
    </Container>
  </>;
}

export default Register;
