import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { P24_URL } from '../config';
import {
  CART_CLEAR_ERROR,
  CART_SET_ADDRESS,
  CART_SET_ERROR,
} from '../constants/cartConstants';
import { USER_CLEAR_ERROR } from '../constants/userConstants';
import {
  createOrder,
  registerPayment,
  saveCart,
  clearCart,
  checkTrialDay,
} from '../actions/cartActions';
import { getUserDetails } from '../actions/userActions';
// import SmallProductCard from '../components/order/SmallProductCard';
import Payment from '../components/order/Payment';
import Message from '../components/layout/Message';
import Loader from '../components/layout/Loader';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import { Helmet } from 'react-helmet-async';
import AppBarOrder from '../components/nav/AppBarOrder';

import AddressSelection from '../components/order/AddressSelection';
import _ from 'lodash';
import Cart from '../components/cart/Cart';
import CartSummary from '../components/cart/CartSummary';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  // titlePage: {
  //   marginBottom: 16,
  //   marginTop: 16,
  //   fontSize: '2rem',
  // },
}));

function Order() {
  const dispatch = useDispatch();
  const { user, message } = useSelector((state) => state.userDetails);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { loading, error, errorTrial, cart, paymentToken, orderCreated } =
    useSelector((state) => state.cart);
  const {
    orderItems,
    productPrice,
    shippingPrice,
    totalPrice,
    address,
    paymentMethod,

    termsFitDiet,
    termsP24,
    termsMarketing,
  } = cart;

  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!user && isAuthenticated) {
      dispatch(getUserDetails());
    }
  }, [dispatch, user, isAuthenticated]);

  useEffect(() => {
    if (user && user.addresses?.length === 0) {
      dispatch({ type: CART_SET_ADDRESS, payload: null });
    }
  }, [dispatch, user]);

  useEffect(() => {
    const isTrialDayInOrder = orderItems.reduce(
      (isTrial, item) => isTrial || item.isTrialDay,
      false
    );
    if (isTrialDayInOrder && user) {
      dispatch(checkTrialDay(user._id));
    }
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/logowanie?redirect=/zamow`);
    }
  }, [history, isAuthenticated]);

  //get token for payment
  useEffect(() => {
    if (orderCreated) {
      dispatch(clearCart());
      if (orderCreated.paymentMethod !== 'Przelew') {
        dispatch(registerPayment(orderCreated));
      } else {
        history.push(`/potwierdzenie-zamowienia/${orderCreated._id}`);
      }
    }
  }, [orderCreated]);

  //redirect for payment
  useEffect(() => {
    if (paymentToken) {
      window.location.href = `${P24_URL}/${paymentToken}`;
    }
  }, [paymentToken]);

  const handleCreateNewOrder = () => {
    if (!address) {
      dispatch({
        type: CART_SET_ERROR,
        payload: 'Proszę, dodaj adres!',
      });
    } else if (!termsFitDiet) {
      dispatch({
        type: CART_SET_ERROR,
        payload: 'Proszę, zaakceptuj regulamin FitDiet!',
      });
    } else {
      dispatch(
        createOrder(user._id, {
          orderItems,
          productPrice,
          shippingPrice,
          totalPrice,
          address,
          paymentMethod,
          termsFitDiet,
          termsP24,
          termsMarketing,
        })
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Zamówienie</title>
      </Helmet>
      <AppBarOrder />
      {(error || errorTrial) && (
        <Message
          message={error || errorTrial}
          handleClose={() => dispatch({ type: CART_CLEAR_ERROR })}
        />
      )}
      {message && (
        <Message
          message={message}
          type='success'
          handleClose={() => dispatch({ type: USER_CLEAR_ERROR })}
        />
      )}
      {loading ? (
        <Loader />
      ) : !orderItems.length && user ? (
        <>
          <Message message='Dodaj produkty do koszyka' />
          <Link to='/sklep'>Dodaj produkty</Link>
        </>
      ) : user ? (
        <Container>
          <div className={classes.titleContainer}>
            <Typography
              variant='h2'
              component='h1'
              sx={{ my: 2 }}
              // className={classes.titlePage}
            >
              Wybierz płatność
            </Typography>
          </div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={8}>
              <Cart />

              <CartSummary isOrder={true} />
            </Grid>
            <Grid item xs={12} md={4}>
              <AddressSelection />
              <Payment />
              <Box mb={2}>
                <Typography variant='body2' color='textSecondary'>
                  Jeśli potrzebujesz fakturę VAT prosimy o kontakt przed
                  złożeniem zamówienia!
                </Typography>
              </Box>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                onClick={handleCreateNewOrder}
                style={{ marginBottom: 36 }}
              >
                Zamów
              </Button>
            </Grid>
          </Grid>
        </Container>
      ) : (
        ''
      )}
    </>
  );
}

export default Order;
