import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../layout/Loader';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(5),
  },

  summaryText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const CartSummary = ({ isOrder }) => {
  const {
    cart: {
      orderItems,
      rebateTrial,
      rebateAddress,
      rebateDuration,
      productBasePrice,
      productPrice,
      shippingPrice,
      totalPrice,
    },
    error,
    loading,
  } = useSelector((state) => state.cart);
  const classes = useStyles();
  const rebateSum = rebateTrial + rebateAddress + rebateDuration;

  return (
    <div className={classes.summaryText}>
      {loading ? (
        <Loader />
      ) : (
        orderItems.length > 0 && (
          <Grid container>
            {rebateSum > 0 && (
              <>
                <Grid item xs={8}>
                  <Typography color='textSecondary' variant='h5'>
                    Koszyk:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h5' color='textSecondary' align='right'>
                    {productBasePrice} zł
                  </Typography>
                </Grid>
              </>
            )}
            {isOrder && rebateSum > 0 ? (
              <>
                <Grid item xs={8}>
                  <Typography color='textSecondary' variant='h5'>
                    Rabaty:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h5' color='textSecondary' align='right'>
                    -{rebateTrial + rebateDuration + rebateAddress} zł
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                {rebateTrial > 0 && (
                  <>
                    <Grid item xs={8}>
                      <Typography color='textSecondary' variant='h5'>
                        Rabat za dzień próbny:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h5'
                        color='textSecondary'
                        align='right'
                      >
                        -{rebateTrial} zł
                      </Typography>
                    </Grid>
                  </>
                )}
                {rebateDuration > 0 && (
                  <>
                    <Grid item xs={8}>
                      <Typography color='textSecondary' variant='h5'>
                        Rabat "Dłuzej i taniej":
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h5'
                        color='textSecondary'
                        align='right'
                      >
                        -{rebateDuration} zł
                      </Typography>
                    </Grid>
                  </>
                )}
                {rebateAddress > 0 && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='h5' color='textSecondary'>
                        Rabat "Razem zawsze taniej":
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h5'
                        align='right'
                        color='textSecondary'
                      >
                        -{rebateAddress} zł
                      </Typography>
                    </Grid>
                  </>
                )}
              </>
            )}
            {!isOrder && (
              <>
                <Grid item xs={8}>
                  <Typography variant='h5'>Razem:</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <hr style={{ marginLeft: '50%' }} /> */}
                  <Typography variant='h5' align='right'>
                    {productPrice - rebateAddress} zł
                  </Typography>
                </Grid>
              </>
            )}
            {isOrder && (
              <>
                <Grid item xs={8}>
                  <Typography variant='h5' color='textSecondary'>
                    Transport:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <hr style={{ marginLeft: '50%' }} /> */}
                  <Typography variant='h5' align='right' color='textSecondary'>
                    {shippingPrice ? `${shippingPrice} zł` : 'gratis'}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='h5'>Suma zamówienia:</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <hr style={{ marginLeft: '50%' }} /> */}
                  <Typography variant='h5' align='right'>
                    {totalPrice} zł
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        )
      )}
    </div>
  );
};

export default CartSummary;
