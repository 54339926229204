import React from 'react';
import TopBarInfo from '../nav/TopBarInfo';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles, useTheme, Theme, createStyles } from '@mui/styles';
import DrawerItems from './DrawerItems';
import clsx from 'clsx';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    // drawer: {
    //   [theme.breakpoints.up('sm')]: {
    //     width: drawerWidth,
    //     flesmhrink: 0,
    //   },
    // },
    // appBar: {
    //   [theme.breakpoints.up('sm')]: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //   },
    // },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    //   [theme.breakpoints.up('sm')]: {
    //     display: 'none',
    //   },
    // },
    // // necessary for content to be below app bar
    // toolbar: theme.mixins.toolbar,
    // drawerPaper: {
    //   width: drawerWidth,
    // },
    // content: {
    //   flexGrow: 1,
    //   padding: theme.spacing(3),
    // },
  })
);

export default function Layout({ children, window }) {
  // const { window } = props;

  const classes = useStyles();
  const theme = useTheme();
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <DrawerItems />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color='default'
      >
        {/* <AppBar position='fixed' className={classes.appBar} color='default'> */}
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            // onClick={handleDrawerToggle}
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.hide)}
            // className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant='h6' noWrap>
            Responsive drawer
          </Typography> */}
          <TopBarInfo />
        </Toolbar>
      </AppBar>
      {/* {isNav && ( */}
      {/* <nav className={classes.drawer} aria-label='mailbox folders'> */}
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {/* <Hidden smUp implementation='css'> */}
      <Drawer
        // container={container}
        className={classes.drawer}
        // variant='temporary'
        variant='persistent'
        anchor='left'
        open={open}
        // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        // open={mobileOpen}
        // onClose={handleDrawerToggle}
        onClose={handleDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        // ModalProps={{
        //   keepMounted: true, // Better open performance on mobile.
        // }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        {drawer}
      </Drawer>
      {/* </Hidden> */}
      {/* <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
      {/* </nav> */}
      {/* )} */}
      <main
        // className={classes.content}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* <div className={classes.toolbar} /> */}
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
