import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { getDiets } from '../actions/dietListAction';
import { ORDERS_CLEAR_ERROR } from '../constants/orderListConstants';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Layout from '../components/layout/Layout';
import DietCard from '../components/activeDiet/DietCard';
import Grid from '@mui/material/Grid';
import AddDietButton from '../components/layout/AddDietButton';
import NoOrderInfo from '../components/order/NoOrderInfo';
import TitleScreen from '../components/layout/TitleScreen';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  noDietsText: {
    marginTop: theme.spacing(2),
  },
}));

function DietListScreen() {
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.auth.profile);
  const { diets, loading, error } = useSelector((state) => state.dietList);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getDiets(_id));
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Aktywne diety</title>
      </Helmet>
      <Container>
        <div className={classes.titleContainer}>
          <TitleScreen title='Moje diety' />
          {/* <Typography className={classes.title} variant='h5'>
            Moje diety
          </Typography> */}
          <AddDietButton text='Zamów' />
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message
            message={error}
            handleClose={() => dispatch({ type: ORDERS_CLEAR_ERROR })}
          />
        ) : diets.length ? (
          <Grid container spacing={3}>
            {diets.map((d) => (
              <Grid item xs={12} sm={6} lg={4} key={d.dietId}>
                <DietCard
                  dietName={d.name}
                  photo={d.photo}
                  startAt={d.startAt}
                  endAt={d.endAt}
                  orderNo={d.orderNo}
                  orderId={d._id}
                  dietId={d.dietId}
                  meals={d.meals}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoOrderInfo />
        )}
      </Container>
    </Layout>
  );
}

export default DietListScreen;
