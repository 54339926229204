import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import isSameDay from 'date-fns/isSameDay';
import Layout from '../components/layout/Layout';
import DayInfo from '../components/dietDetails/DayInfo';
import DietInfo from '../components/dietDetails/DietInfo';

import { getOrderItem } from '../actions/orderDetailsAction';
import Container from '@mui/material/Container';

import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';
import Calendar from '../components/dietDetails/Calendar';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// const useStyles = makeStyles((theme) => ({
//   deliveryBox: {
//     // display: 'flex',
//   },
// }));

const DietScreen = (props) => {
  const queryStr = queryString.parse(props.location.search);
  const dispatch = useDispatch();
  const { orderId, itemId } = queryStr;
  const { orderItem, loading, error } = useSelector((state) => state.orderItem);
  const [deliveryDays, setDeliveryDays] = useState(null);
  const [clickedDay, setClickedDay] = useState();
  // const classes = useStyles();

  useEffect(() => {
    dispatch(getOrderItem(orderId, itemId));
  }, [dispatch, orderId, itemId]);

  useEffect(() => {
    if (orderItem?._id === itemId) {
      const days = [...orderItem.deliveryDays];
      const currentDay = days.find((d) =>
        isSameDay(new Date(d.deliveredAt), new Date())
      );
      setDeliveryDays(days);
      setClickedDay(currentDay);
    }
  }, [orderItem, loading]);

  const handleClickDay = (info) => {
    const day = deliveryDays.find((d) => d._id === info.event.id);
    setClickedDay(day);
  };

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message message={error} />
      ) : (
        <Container>
          <DietInfo
            orderNr={orderItem.orderNo}
            fullDietName={`${orderItem.name} ${orderItem.calories}`}
            startAt={orderItem.startAt}
            endAt={orderItem.endAt}
            photo={orderItem.photo}
          />
          {deliveryDays && (
            <Grid container spacing='3'>
              <Grid item xs={12} md={8}>
                <Calendar
                  deliveryDays={deliveryDays}
                  handleClickDay={handleClickDay}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {clickedDay && (
                  <DayInfo
                    deliveredAt={new Date(clickedDay.deliveredAt)}
                    status={clickedDay.status}
                    dietName={`${clickedDay.diet} ${clickedDay.calories}`}
                    street={`${clickedDay.address.street} ${
                      clickedDay.address.aptNo ? clickedDay.address.aptNo : ''
                    }`}
                    city={clickedDay.address.city}
                    meals={clickedDay.meals}
                  />
                )}
              </Grid>
            </Grid>
          )}

          <Link to='/'>Wróć</Link>
        </Container>
      )}
    </Layout>
  );
};

export default DietScreen;
