import api from '../utils/api';

import {
  DIET_LIST_REQUEST,
  DIET_LIST_SUCCESS,
  DIET_LIST_FAIL,
} from '../constants/dietListConstants';

export const getDiets = (userId) => async (dispatch) => {
  try {
    dispatch({ type: DIET_LIST_REQUEST });
    const res = await api.get(`users/${userId}/orders/diets`);
    dispatch({ type: DIET_LIST_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: DIET_LIST_FAIL,
      payload: 'Upss... w tej chwili nie możemy wyświetlić Twoich zamówień :(',
    });
  }
};
