export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const AUTH_PROFILE_REQUEST = 'AUTH_PROFILE_REQUEST';
export const AUTH_PROFILE_SUCCESS = 'AUTH_PROFILE_SUCCESS';
export const AUTH_PROFILE_FAIL = 'AUTH_PROFILE_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const AUTH_PASSWORD_UPDATE_REQUEST = 'AUTH_PASSWORD_UPDATE_REQUEST';
export const AUTH_PASSWORD_UPDATE_SUCCESS = 'AUTH_PASSWORD_UPDATE_SUCCESS';
export const AUTH_PASSWORD_UPDATE_FAIL = 'AUTH_PASSWORD_UPDATE_FAIL';

export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

export const FORGOT_PASSWORD_MAIL_REQUEST = 'FORGOT_PASSWORD_MAIL_REQUEST';
export const FORGOT_PASSWORD_MAIL_SUCCCESS = 'FORGOT_PASSWORD_MAIL_SUCCCESS';
export const FORGOT_PASSWORD_MAIL_ERROR = 'FORGOT_PASSWORD_MAIL_ERROR';

export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAIL = 'AUTH_RESET_PASSWORD_FAIL';
