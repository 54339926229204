export const OPTION_GET_CITIES_REQUEST = 'OPTION_GET_CITIES_REQUEST';
export const OPTION_GET_CITIES_SUCCESS = 'OPTION_GET_CITIES_SUCCESS';
export const OPTION_GET_CITIES_FAIL = 'OPTION_GET_CITIES_FAIL';

export const OPTION_DIET_DAILY_DISCOUNTS_REQUEST =
  'OPTION_DIET_DAILY_DISCOUNTS_REQUEST';
export const OPTION_DIET_DAILY_DISCOUNTS_SUCCESS =
  'OPTION_DIET_DAILY_DISCOUNTS_SUCCESS';
export const OPTION_DIET_DAILY_DISCOUNTS_FAIL =
  'OPTION_DIET_DAILY_DISCOUNTS_FAIL';

export const OPTION_DELIVERY_ZONE_REQUEST = 'OPTION_DELIVERY_ZONE_REQUEST';
export const OPTION_DELIVERY_ZONE_SUCCESS = 'OPTION_DELIVERY_ZONE_SUCCESS';
export const OPTION_DELIVERY_ZONE_FAIL = 'OPTION_DELIVERY_ZONE_FAIL';

export const OPTION_DELIVERY_PRICE_REQUEST = 'OPTION_DELIVERY_PRICE_REQUEST';
export const OPTION_DELIVERY_PRICE_SUCCESS = 'OPTION_DELIVERY_PRICE_SUCCESS';
export const OPTION_DELIVERY_PRICE_FAIL = 'OPTION_DELIVERY_PRICE_FAIL';

export const OPTION_HOURS_TO_REQUEST = 'OPTION_HOURS_TO_REQUEST';
export const OPTION_HOURS_TO_SUCCESS = 'OPTION_HOURS_TO_SUCCESS';
export const OPTION_HOURS_TO_FAIL = 'OPTION_HOURS_TO_FAIL';

export const OPTION_DELIVERY_ZONE_RESET = 'OPTION_DELIVERY_ZONE_RESET';
