import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getLocationDetails } from '../../../utils/userApi.ts';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
}));

const AddressPrice = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { location } = values;

  const locationDetailsQuery = useQuery(['locationDetails', location], () =>
    getLocationDetails(location)
  );

  return (
    <div>
      <Collapse in={locationDetailsQuery.data && location}>
        <Alert severity='success' className={classes.alert}>
          {!locationDetailsQuery.data?.price
            ? 'Świetnie!!! Twoja dostawa będzie całkowicie bezpłatna!!!'
            : `Koszt dowozu do tej lokalizacji to tylko ${locationDetailsQuery.data.price} zł za dostawę`}
        </Alert>
      </Collapse>
    </div>
  );
};

export default AddressPrice;
