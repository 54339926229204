import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import { isWithinInterval } from 'date-fns';
import { endOfMonth } from 'date-fns';

const createHolidaySchedule = () => {
  const holidaySchedule = [
    {
      from: '2023-08-10T16:00:00',
      to: '2023-08-11T15:59:59',
      minDate: '2023-08-16',
    },
    {
      from: '2023-08-11T16:00:00',
      to: '2023-08-15T23:59:59',
      minDate: '2023-08-17',
    },
  ];
  return holidaySchedule.map((item) => ({
    from: new Date(item.from),
    to: new Date(item.to),
    minDate: new Date(item.minDate),
  }));
};

// const blockedDays: string[] = [
//   '2024-02-27',
//   '
// ];

//************* */
//This part is create for semi-closing Fitdiet system for orders
const startDate = new Date('2024-02-27');
const endDate = endOfMonth(new Date('2024-04-01'));
const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
const blockedDays: string[] = dateRange.map((date) =>
  format(date, 'yyyy-MM-dd')
);
//************* */

//creates arrray with blocked days
const createBlockedDays = () => {
  const blockedDates: Date[] = [];
  blockedDays.forEach((item) => blockedDates.push(new Date(item)));
  return blockedDates;
};

// creates range of days that are blocked in the system to prevent orders with days out of the schedule
export const createDisabledDays = () => {
  let disabledDays = [];

  let min = 1;
  const today = new Date();
  const day = today.getDay();
  const hour = today.getHours();
  const minutes = today.getMinutes();
  const holidaySchedule = createHolidaySchedule();

  //Standardowe blokady na kalendarzu
  //Obecny harmonogram skladania zamowien (corona)
  if (day === 4 && hour >= 16 && minutes >= 0) {
    min = 5;
  } else if (day === 5 && hour < 16 && minutes >= 0) {
    min = 4;
  } else if (day === 5 && hour >= 16 && minutes >= 0) {
    min = 5;
  } else if (day === 6) {
    min = 4;
  } else if (day === 0) {
    min = 3;
  } else if (hour >= 16 && minutes >= 0) {
    min = 3;
  } else if (hour < 16 && minutes >= 0) {
    min = 2;
  }

  const holidayMinDateItem = holidaySchedule.find((item) => {
    return isWithinInterval(today, { start: item.from, end: item.to });
  });

  if (holidayMinDateItem) {
    const minDate = new Date(holidayMinDateItem.minDate);
    const todayStartDay = new Date(format(today, 'yyyy-MM-dd'));
    min = differenceInDays(minDate, todayStartDay); //+ 1; //recznie dodane 1, bo cos sie zle liczy automatycznie
  }

  const disabledBefore = addDays(today, min);
  const blockedDates = createBlockedDays();
  disabledDays = [
    ...blockedDates,
    { before: disabledBefore, after: new Date('2024-2-27') },
  ];

  // console.log(holidayMinDateItem);
  return disabledDays;
};

//function used in reducer to create selected delivery days from start, end dates taking into considaration sat and sun
export const createSelectedDays = (
  start: Date,
  end: Date,
  isSaturdayBlocked: boolean,
  isSundayBlocked: boolean
) => {
  let dayRange = [];
  const blockedDays = createBlockedDays();

  if (isBefore(start, end) || isSameDay(start, end)) {
    dayRange = eachDayOfInterval({ start, end });
    if (isSaturdayBlocked) dayRange = dayRange.filter((d) => !isSaturday(d));
    if (isSundayBlocked) dayRange = dayRange.filter((d) => !isSunday(d));

    //filter out blocked days from the range of days
    if (dayRange.length > 1) {
      dayRange = dayRange.filter((d) => {
        const blockDayIndex = blockedDays.findIndex((blockedDay) =>
          isSameDay(d, blockedDay)
        );
        return blockDayIndex === -1 ? true : false;
      });
    }
  } else {
    dayRange = [start];
  }

  //convert dates to start of the UTC day
  const dayRangeISO = dayRange.map(
    (d) =>
      new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0))
  );
  return dayRangeISO;
};
