import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  rootLabel: {
    width: '100%',
    color: theme.palette.text.secondary,
    border: '1.5px solid #eaeaea',
    borderRadius: 6,
    marginTop: 12,
    padding: 18,
    transition: 'color 0.15s ease, border-color 0.15s ease',
    marginLeft: 0,
    '&:hover': {
      borderColor: '#c00044',
      color: theme.palette.text.primary,
      background: '#ededed',
    },
  },
  label: { width: '100%' },
  selectedLabel: {
    color: theme.palette.text.primary,
    border: '2px solid #c00044',
    boxShadow: '1px 1px spread',
  },
}));

const useStylesLabel = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondaryInfo: {
    dispalay: 'flex',
  },
}));

const Label = ({ optionName, secondaryInfo = '' }) => {
  const classes = useStylesLabel();
  return (
    <div className={classes.root}>
      <div className={classes.option}>{optionName}</div>
      <div className={classes.selectedLabel}>{secondaryInfo}</div>
    </div>
  );
};

export default function CustomizedRadios({
  label,
  optionList,
  changeHandler,
  value,
}) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(event.target.value);
  };

  return (
    <div>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>{label}</FormLabel>
        <RadioGroup
          aria-label={label}
          name={label}
          onChange={handleChange}
          value={value}
        >
          {optionList.map((o) => (
            <FormControlLabel
              key={o.value}
              className={clsx(
                classes.rootLabel,
                value === o.value && classes.selectedLabel
              )}
              classes={{
                label: classes.label,
              }}
              value={o.value}
              control={<Radio />}
              label={
                <Label optionName={o.name} secondaryInfo={o.secondaryInfo} />
              }
              disabled={o.isDisabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
