import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

export default function CustomizedSwitch({ label, optionList, updateState }) {
  // const [state, setState] = React.useState({
  //   isSaturday: true,
  //   isSunday: true,
  // });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    updateState(event.target.name, event.target.checked);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <FormGroup>
        {optionList?.map((o) => (
          <FormControlLabel
            key={o.name}
            control={
              <Switch
                checked={o.isChecked}
                onChange={handleChange}
                name={o.name}
              />
            }
            label={o.label}
            // label='chcę dostawy w sobotę'
          />
        ))}
      </FormGroup>
      {/* <FormHelperText>Be careful</FormHelperText> */}
    </FormControl>
  );
}

// CustomizedSwitch.propTypes = {};
