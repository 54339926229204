import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_PRICE_UPDATE,
  CART_UPDATE_PAYMENT,
  CART_SET_ADDRESS,
  CART_LOAD_REQUEST,
  CART_LOAD_SUCCESS,
  CART_LOAD_LOCAL,
  CART_LOAD_FAIL,
  CART_SAVE_REQUEST,
  CART_SAVE_SUCCESS,
  CART_SAVE_FAIL,
  CART_CREATE_ORDER_REQUEST,
  CART_CREATE_ORDER_SUCCESS,
  CART_CREATE_ORDER_FAIL,
  CART_REGISTER_PAYMENT_REQUEST,
  CART_REGISTER_PAYMENT_SUCCESS,
  CART_REGISTER_PAYMENT_FAIL,
  CART_IS_TRIAL_REQUEST,
  CART_IS_TRIAL_SUCCESS,
  CART_IS_TRIAL_FAIL,
  CART_TOGGLE_VALUE,
  CART_TOGGLE_ALL_TERMS,
  CART_CLEAR,
  CART_SET_ERROR,
  CART_CLEAR_ERROR,
} from '../constants/cartConstants';
import produce from 'immer';

const initCurrentCart = {
  loading: false,
  error: null,
  errorTrial: null,
  orderCreated: null,
  shouldSave: false,
  cart: {
    orderItems: [],
    productPrice: 0,
    nrDeliveries: 0,
    rebateAddress: 0,
    rebateDuration: 0,
    rebateTrial: 0,
    shippingPrice: 0,
    totalPrice: 0,
    // cities: [],
    paymentMethod: 'Przelew',
    address: null,
    paymentToken: null,
    termsAllAccepted: false,
    termsFitDiet: false,
    termsP24: false,
    isTermsP24Hidden: true,
    termsMarketing: false,
  },
};

const cartReducer = produce((draft, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case CART_ADD_ITEM:
      draft.cart.orderItems.push(payload);
      draft.shouldSave = true;
      return;

    case CART_REMOVE_ITEM:
      draft.cart.orderItems.splice(payload, 1);
      draft.shouldSave = true;
      return;

    case CART_PRICE_UPDATE:
      // draft.cart.totalPrice = draft.cart.orderItems.reduce(
      //   (acc, i) => acc + i.price,
      //   0
      // );
      // draft.cart.totalPrice -= draft.cart.rebateAddress;
      // draft.cart.totalPrice += draft.cart.shippingPrice;
      draft.cart.productPrice = draft.cart.orderItems.reduce(
        (price, item) => price + item.price,
        0
      );
      return;

    case CART_UPDATE_PAYMENT:
      draft.cart.paymentMethod = payload;
      return;

    case CART_SET_ADDRESS:
      draft.cart.address = payload;
      draft.shouldSave = true;
      return;

    case CART_CREATE_ORDER_REQUEST:
    case CART_REGISTER_PAYMENT_REQUEST:
    case CART_LOAD_REQUEST:
    case CART_SAVE_REQUEST:
    case CART_IS_TRIAL_REQUEST:
      draft.loading = true;
      draft.error = null;
      draft.shouldSave = false;
      return;

    case CART_SAVE_SUCCESS:
      draft.loading = false;
      // draft.shouldSave = false;
      draft.cart = payload;
      // draft.cart.orderItems = payload.orderItems;
      // draft.cart.productPrice = payload.productPrice;
      // draft.cart.nrDeliveries = payload.nrDeliveries;
      // draft.cart.shippingPrice = payload.shippingPrice;
      // draft.cart.totalPrice = payload.totalPrice;
      return;

    case CART_LOAD_SUCCESS:
    case CART_LOAD_LOCAL:
      draft.loading = false;
      draft.cart = payload;
      return;
    case CART_CREATE_ORDER_SUCCESS:
      draft.loading = false;
      draft.orderCreated = payload;
      return;

    case CART_IS_TRIAL_SUCCESS:
      draft.loading = false;
      if (!payload) {
        const newOrderItems = draft.cart.orderItems.filter(
          (i) => !i.isTrialDay
        );
        draft.cart.orderItems = [...newOrderItems];
        draft.shouldSave = true;
        draft.errorTrial =
          'Dzień próbny został usunięty z koszyka. Był już wcześniej wykorzystany!';
      }
      return;
    // case CART_CREATE_ORDER_FAIL:
    //   draft.loading = false;
    //   draft.error = 'Błąd przy zapisywaniu zamówienia';
    //   return;

    case CART_REGISTER_PAYMENT_SUCCESS:
      draft.loading = false;
      draft.paymentToken = payload;
      return;

    case CART_REGISTER_PAYMENT_FAIL:
    case CART_CREATE_ORDER_FAIL:
    case CART_LOAD_FAIL:
    case CART_SAVE_FAIL:
    case CART_IS_TRIAL_FAIL:
      draft.loading = false;
      draft.error = payload;
      return;

    case CART_TOGGLE_VALUE:
      draft.cart[payload.name] = payload.value;
      return;

    case CART_TOGGLE_ALL_TERMS:
      draft.cart.termsFitDiet = payload;
      draft.cart.termsP24 = payload;
      draft.cart.termsMarketing = payload;
      return;

    case CART_CLEAR:
      return initCurrentCart;

    case CART_SET_ERROR:
      draft.loading = false;
      draft.error = payload;
      return;

    case CART_CLEAR_ERROR:
      draft.error = null;
      draft.errorTrial = null;
      return;
  }
}, initCurrentCart);

export default cartReducer;
