import React, { useEffect } from 'react';
import { convertPlaceString } from '../../../utils/userApi.ts';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { addAddress, updateAddress } from '../../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { OPTION_DELIVERY_ZONE_RESET } from '../../../constants/optionConstants';
import { USER_CLEAR_ERROR } from '../../../constants/userConstants';

import AddressPrice from './AddressPrice';
import HoursField from './HoursField.tsx';
import LocationField from './LocationField.tsx';
import { useStylesField } from './styles.ts';
import { Box } from '@mui/material';

const addressSchema = Yup.object({
  location: Yup.string().nullable().required('Proszę wybierz miejscowość'),
  street: Yup.string().required('Proszę podaj ulicę'),
  buildingNr: Yup.string().required('Proszę podaj numer budynku'),
  zipCode: Yup.string()
    .required('Proszę podaj kod pocztowy')
    .matches(/\d{2}-\d{3}/, {
      message: 'Sprawdź czy kod pocztowy jest prawidłowy',
    }),
  comments: Yup.string().required(
    'Proszę dodaj informacje odnośnie Twojej dostawy'
  ),
});

const AddressEdit = ({ onClose, open, isNew, addressId }) => {
  const dispatch = useDispatch();
  const classes = useStylesField();
  const { addresses, _id: userId } = useSelector(
    (state) => state.userDetails.user
  );
  const address = addresses?.find((a) => a._id === addressId);
  const initialValues = {
    street: address?.street || '',
    buildingNr: address?.buildingNr || '',
    aptNr: address?.aptNr || '',
    location: address?.city ? `${address.city}, ${address.voivodeship}` : '',
    zipCode: address?.zipCode || '',
    toTime: address?.toTime,
    comments: address?.comments || '',
  };

  useEffect(() => {
    dispatch({ type: OPTION_DELIVERY_ZONE_RESET });
    dispatch({ type: USER_CLEAR_ERROR });
  }, [dispatch]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{isNew ? 'Dodaj adres' : 'Zmień swój adres'}</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={addressSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { city, voivodeship } = convertPlaceString(values.location);
          const updatedAddress = {
            ...values,
            _id: addressId,
            city,
            voivodeship,
            // deliveryPrice: deliveryPrice.price,
          };
          if (isNew) {
            dispatch(addAddress(userId, updatedAddress));
          } else {
            dispatch(updateAddress(userId, updatedAddress));
          }

          setSubmitting(false);
          onClose();
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, values }) => (
          <Form>
            <>
              <DialogContent>
                <AddressPrice />
                <LocationField />
                <Box mt={3} />
                <Field
                  component={TextField}
                  name='zipCode'
                  type='text'
                  label='Kod pocztowy'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                  // disabled
                />
                <Field
                  component={TextField}
                  name='street'
                  type='text'
                  label='Ulica'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='buildingNr'
                  type='text'
                  label='Nr budynku'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='aptNr'
                  type='text'
                  label='Nr lokalu'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <HoursField />

                <Field
                  component={TextField}
                  name='comments'
                  type='text'
                  label='Informacje dodatkowe odnośnie dostawy'
                  placeholder='Napisz nam w jakim miejscu zostawić Twoją paczkę. Jeśli możesz to podaj tez kod do drzwi.Pomóż nam w tym, żebyś mógł szybko i bezpiecznie otrzymać swoje zestawy!'
                  multiline
                  rows={3}
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => onClose()}
                >
                  Zamknij
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Zapisz
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddressEdit;
