import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { loadUser } from '../actions/authActions';
import Loader from '../components/layout/Loader';
import setAuthToken from '../utils/setAuthToken';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { profile, token, isAuthenticated, loading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  let history = useHistory();

  // useEffect(() => {
  //   if (!user) {
  //     dispatch(loadUser());
  //   }
  // }, [user]);

  useEffect(() => {
    if (!isAuthenticated && localStorage.token) {
      setAuthToken(localStorage.token);
      dispatch(loadUser());
    }
    if (!localStorage.token) history.push('/logowanie');
  }, [isAuthenticated, localStorage]);

  useEffect(() => {});

  return (
    <Route
      {...rest}
      render={
        (props) =>
          loading ? (
            <Loader />
          ) : isAuthenticated ? (
            <Component {...props} />
          ) : null
        // <Redirect
        //   to={{
        //     pathname: '/login',
        //     state: { from: props.location },
        //   }}
        // />
      }
    />
  );
};

export default PrivateRoute;
