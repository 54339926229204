import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Field, FormikProps, useFormikContext } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import { getCities } from '../../../utils/userApi';
import { useStylesField } from './styles';
import MuiTextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {}

const LocationField = (props: Props) => {
  const classes = useStylesField();
  const [openCity, setOpenCity] = useState(false);
  const [citySearchStr, setCitySearchStr] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const cityQuery = useQuery(['cityQuery', citySearchStr], () =>
    getCities(citySearchStr)
  );

  const loadingCities = openCity && cityOptions.length === 0;

  const { touched, errors }: FormikProps<AddressData> = useFormikContext();

  const citySelectionHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // dont fire API if the user delete or not entered anything
    if (
      (ev.target.value !== '' || ev.target.value !== null) &&
      ev.target.value.length > 2
    ) {
      setCitySearchStr(ev.target.value);
    } else {
      setCitySearchStr('');
      setCityOptions([]);
    }
  };

  useEffect(() => {
    let active = true;

    if (!loadingCities) {
      return undefined;
    }
    if (active && cityQuery.data && citySearchStr) {
      setCityOptions(cityQuery.data);
    }

    return () => {
      active = false;
    };
  }, [loadingCities, cityQuery.data, citySearchStr]);

  useEffect(() => {
    if (!openCity) {
      setCityOptions([]);
      setCitySearchStr('');
    }
  }, [openCity]);

  return (
    <Field
      name='location'
      open={openCity}
      noOptionsText={'Wpisz 3 pierwsze litery swojej miejscowości'}
      onOpen={() => {
        setOpenCity(true);
      }}
      onClose={() => {
        setOpenCity(false);
      }}
      component={Autocomplete}
      options={cityOptions}
      getOptionLabel={(option: string) => option}
      getOptionSelected={(option: string, value: string) => {
        return option === value;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          {...params}
          error={touched.location && !!errors.location}
          helperText={touched.location && errors.location}
          label='Miejscowość'
          name='location'
          fullWidth
          variant='outlined'
          className={classes.field}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {cityQuery.isLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={citySelectionHandler}
        />
      )}
    ></Field>
  );
};

export default LocationField;
