import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );
  return (
    <ListItem button component={CustomLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const DrawerItems = () => {
  return (
    <List>
      <ListItemLink
        icon=<FontAwesomeIcon
          style={{ marginLeft: 3 }}
          icon={faUtensils}
          size='lg'
        />
        primary='Moje diety'
        to='/'
      />
      <ListItemLink
        icon={<ShoppingCartIcon />}
        primary='Moje zamówienia'
        to='/historia'
      />
      <ListItemLink
        icon={<AccountCircleIcon />}
        primary='Moje konto'
        to='/profil'
      />
    </List>
  );
};

export default DrawerItems;
