import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Message from '../components/layout/Message';
import { CART_CLEAR_ERROR } from '../constants/cartConstants';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBarOrder from '../components/nav/AppBarOrder';
import Cart from '../components/cart/Cart';
import CartSummary from '../components/cart/CartSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titlePage: {
    marginBottom: 16,
    marginTop: 16,
    fontSize: '2rem',
  },
  productCard: {
    marginBottom: theme.spacing(2),
  },
  summaryText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
const CartScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { cart } = useSelector((state) => state.cart);
  const { error } = cart;

  let history = useHistory();

  return (
    <>
      <Helmet>
        <title>Koszyk</title>
      </Helmet>
      <>
        <AppBarOrder />
        <Container>
          <Cart />
          <div className={classes.root}>
            {error ? (
              <Message
                message={error}
                handleClose={() => dispatch({ type: CART_CLEAR_ERROR })}
              />
            ) : (
              <Grid container>
                <Grid xs={12} md={12} item>
                  <CartSummary />
                </Grid>
                <div style={{ width: '100%' }}>
                  <Button
                    style={{ float: 'right' }}
                    variant='contained'
                    color='secondary'
                    onClick={() => history.push('/zamow')}
                  >
                    Zamów
                  </Button>
                </div>
              </Grid>
            )}
          </div>
        </Container>
      </>
    </>
  );
};

export default CartScreen;
