import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Paper } from '@mui/material';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
  priceContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  img: {
    margin: 'auto',
  },
});

const ProductCard = ({ cartItem, remove, idx }) => {
  const classes = useStyles();
  return (
    <div>
      <Box
      // mt={}
      >
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} className={classes.img}>
                <img
                  src={`/diets/${cartItem.product.photo}`}
                  // src={`${API_URL}/api/v1/uploads/${cartItem.product.photo}`}
                  width='150'
                  alt={`${cartItem.product.name}`}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {`${cartItem.name} ${cartItem.calories} kcal`}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {`Początek: ${format(
                    new Date(cartItem.deliveryDays[0]),
                    'd.MM.yyyy'
                  )}`}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {`Koniec: ${format(
                    new Date(
                      cartItem.deliveryDays[cartItem.deliveryDays.length - 1]
                    ),
                    'd.MM.yyyy'
                  )}`}
                </Typography>
                {cartItem.qty > 1 && (
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    component='p'
                  >
                    {`Liczba diet: ${cartItem.qty}`}
                  </Typography>
                )}
                <Typography variant='body2' color='textSecondary' component='p'>
                  {`Liczba dni: ${cartItem.deliveryDays.length}`}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {`Cena za 1 dzień: ${cartItem.dailyRateBase} zł`}
                </Typography>
                {cartItem.dailyRateDiscount ? (
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    component='p'
                  >
                    {cartItem.priceDiscountTrial
                      ? `Rabat za dzień próbny: ${cartItem.priceDiscountTrial} zł`
                      : cartItem.priceDurationDiscount
                      ? `Rabat "Dłużej i taniej: ${cartItem.priceDurationDiscount} zł`
                      : ''}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <div className={classes.priceContainer}>
                  <Typography
                    // align='right'
                    gutterBottom
                    variant='h5'
                    component='h2'
                  >
                    {`${cartItem.priceBase} zł`}
                  </Typography>
                  <IconButton onClick={() => remove(idx)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ProductCard;
