import api from './api';
// ADDRESS
// Get all voivodeships

export const getVoivodeships = async () => {
  const data = await api.get('/deliveryZones/voivodeship');
  return data.data.data;
};

export const getCities = async (citySearch: string) => {
  if (citySearch === '') return [];
  const data = await api.get(`deliveryZones/cities/${citySearch}`);
  return data.data.data;
};

export const getLocationDetails = async (placeStr: string) => {
  if (placeStr === '') return [];
  const { city, voivodeship } = convertPlaceString(placeStr);

  const data = await api.get(
    `deliveryzones/by-location/${city}/${voivodeship}`
  );
  return data.data.data;
};

export const convertPlaceString = (placeStr: string) => {
  const placeArr: Array<string> = placeStr.split(', ');
  return {
    city: placeArr[0],
    voivodeship: placeArr[1],
  };
};
