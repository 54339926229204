import React from 'react';
import Alert from '@material-ui/lab/Alert';

const Message = ({
  type = 'error',
  message = 'Błąd aplikacji',
  handleClose = () => console.log('zamkniecie'),
}) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Alert onClose={() => handleClose()} severity={type}>
        {message}
      </Alert>
    </div>
  );
};

export default Message;
