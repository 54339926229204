import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Message from './layout/Message';

const ReturningUserInfo = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div>
      <Message
        message={
          <>
            Jeśli wcześniej zamawiałeś FitDiet to skorzystaj z opcji
            odzyskiwania hasła.
            <Link to='/info'>(więcej informacji...)</Link>
          </>
        }
        type='info'
        handleClose={() => setIsVisible(false)}
      />
    </div>
  );
};

export default ReturningUserInfo;
