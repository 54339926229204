import api from '../utils/api';
// import { setAlert } from './alert';

import {
  OPTION_GET_CITIES_REQUEST,
  OPTION_GET_CITIES_SUCCESS,
  OPTION_GET_CITIES_FAIL,
  OPTION_DIET_DAILY_DISCOUNTS_REQUEST,
  OPTION_DIET_DAILY_DISCOUNTS_SUCCESS,
  OPTION_DIET_DAILY_DISCOUNTS_FAIL,
  OPTION_DELIVERY_ZONE_REQUEST,
  OPTION_DELIVERY_ZONE_SUCCESS,
  OPTION_DELIVERY_ZONE_FAIL,
  OPTION_DELIVERY_PRICE_REQUEST,
  OPTION_DELIVERY_PRICE_SUCCESS,
  OPTION_DELIVERY_PRICE_FAIL,
  OPTION_HOURS_TO_REQUEST,
  OPTION_HOURS_TO_SUCCESS,
  OPTION_HOURS_TO_FAIL,
} from '../constants/optionConstants';

export const getDailyDiscounts = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_DIET_DAILY_DISCOUNTS_REQUEST });
    const { data } = await api.get(`/options/daily-discounts`);
    dispatch({
      type: OPTION_DIET_DAILY_DISCOUNTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: OPTION_DIET_DAILY_DISCOUNTS_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
