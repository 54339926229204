import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../actions/orderListAction';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
// import QRCode from 'react-qr-code';
// import QRCode from 'qrcode';
import TagManager from 'react-gtm-module';
import { GAPurchase } from '../utils/GAEvents';
// import { getOrderItem } from '../actions/orderDetailsAction';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function OrderConfirmationScreen(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { orderId } = props.match.params;
  const {
    _id: userId,
    fName,
    sName,
    email,
  } = useSelector((state) => state.auth.profile);

  const orders = useSelector((state) => state.orders.orders);
  const order = orders.find((o) => o._id === orderId);
  // const { order, loading, error } = useSelector((state) => state.order);
  // const { no } = order;
  // const
  if (order) {
    //send info regarding enhanced conversion value for google ads
    GAPurchase(order, email);
  }

  // useEffect(() => {
  //   if (order) {
  //     console.log(order);
  //
  //   }
  // }, [order]);

  // const generateQR = async text =>{
  //   try {
  //     const qrcode = await QRCode.toDataURL(text)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    dispatch(getOrders(userId));
  }, [dispatch, userId]);

  return (
    <Layout>
      <Container>
        <div className={classes.header}>
          <Typography variant='h3' component='h1' align='center'>
            Dziękujemy za Twoje zamówienie
          </Typography>
        </div>
        <Typography variant='body1'>
          Ze swojej strony rozpoczeliśmy przygotowania do realizacji dostaw pod
          Twoje drzwi. Przestań się martwić gotowaniem, zrelaksuj się i
          przygotuj się na swój pierwszy, pyszny posiłek z tego zamówienia.
          Obiecujemy, że dołożymy wszelkich starań, żebyś otrzymał zdrowe, ale
          również smaczne dania. Sprawdź szczegóły poniżej.
        </Typography>
        <br />
        {order && !order?.isPaid && order?.paymentMethod === 'Przelew' && (
          <>
            <Typography varaint='body1'>
              Dziękujemy za złozone zamówienie. W tym momencie czekamy jeszcze
              na płatność. Jeżeli jest ona już zrealizowana to daj nam chwilę,
              żebyśmy mogli ją zaksięgować. W przeciwnym przypadku prośba o
              wykonanie przelewu na:
            </Typography>
            <Typography variant='body1' align='center'>
              <br />
              <br />
              Fitdiet Poland Sp. z o.o
              <br />
              31 1140 2004 0000 3702 7694 9982 <br />
              Kwota: {order.totalPrice} zł
              <br />
              Tytuł: "Zamówienie nr {order.no} {fName} {sName}"
              <br />
              <br />
            </Typography>
            {/* <Typography variant='body1' align='center'>
              Jeśli chcesz to mozesz tez zeskanować kod QR w swojej aplikacji
              bankowej. <br />
              <br />
            </Typography>
            <QRCode
              value={`||31114020040000370276949982|${order.totalPrice}|Fitdiet Poland Sp. z o.o.|Zamówienie ${order.no} ${fName} ${sName}|||`}
            /> */}
          </>
        )}
        <br />
        <Link to='/historia'>Szczegóły Twojego zamówienia</Link>
      </Container>
    </Layout>
  );
}

export default OrderConfirmationScreen;
