// export const GET_USERS = 'GET_USERS';
export const USER_ERROR = 'USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';

export const USER_ADDRESS_NEW_REQUEST = 'USER_ADDRESS_NEW_REQUEST';
export const USER_ADDRESS_NEW_SUCCESS = 'USER_ADDRESS_NEW_SUCCESS';
export const USER_ADDRESS_NEW_FAIL = 'USER_ADDRESS_UPDATE_FAIL';

export const USER_ADDRESS_UPDATE_REQUEST = 'USER_ADDRESS_UPDATE_REQUEST';
export const USER_ADDRESS_UPDATE_SUCCESS = 'USER_ADDRESS_UPDATE_SUCCESS';
export const USER_ADDRESS_UPDATE_FAIL = 'USER_ADDRESS_UPDATE_FAIL';
