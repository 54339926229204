import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedRadios from '../layout/CustomizedRadios';
import {
  CART_TOGGLE_VALUE,
  CART_TOGGLE_ALL_TERMS,
  CART_UPDATE_PAYMENT,
} from '../../constants/cartConstants';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MULink from '@mui/material/Link';

import CustomizedCheckBox from '../layout/CustomizedCheckBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const paymentOptions = [
  {
    name: 'Przelew bankowy',
    value: 'Przelew',
    secondaryInfo: <AccountBalanceIcon style={{ fontSize: '3rem' }} />,
  },
  {
    name: 'Przelewy24',
    value: 'P24',
    secondaryInfo: (
      <img
        src={`/payment/Przelewy24_logo.svg`}
        // src={`${API_URL}/api/v1/uploads/payment/Przelewy24_logo.svg`}
        width={80}
        alt='Logo Przelewy24'
      />
    ),
  },
  {
    name: 'Blik',
    value: 'Blik',
    secondaryInfo: (
      <img
        src={`/payment/Blik.svg`}
        // src={`${API_URL}/api/v1/uploads/payment/Blik.svg`}
        width={80}
        alt='Logo Blik'
      />
    ),
  },
  // {
  //   name: 'Karty płatnicze',
  //   value: 'Karta',
  //   secondaryInfo: (
  //     <div style={{ display: 'flex' }}>
  //       <img
  //         style={{ marginRight: 6 }}
  //         src={`${API_URL}/api/v1/uploads/payment/Visa.svg`}
  //         width={40}
  //       />
  //       <img
  //         src={`${API_URL}/api/v1/uploads/payment/Mastercard.svg`}
  //         width={40}
  //       />
  //     </div>
  //   ),
  // },
  // {
  //   name: 'Pay Po',
  //   value: 'PayPo',
  //   secondaryInfo: (
  //     <img src={`${API_URL}/api/v1/uploads/payment/Pay Po.svg`} width={80} />
  //   ),
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBotton: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
}));

const Payment = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { cart } = useSelector((state) => state.cart);

  const {
    paymentMethod,
    termsAllAccepted,
    termsFitDiet,
    isTermsP24Hidden,
    termsP24,
    termsMarketing,
  } = cart;

  useEffect(() => {
    const isTermsP24Hidden = ['Przelew', 'P24'].includes(paymentMethod);

    dispatch({
      type: CART_TOGGLE_VALUE,
      payload: { name: 'isTermsP24Hidden', value: isTermsP24Hidden },
    });
  }, [paymentMethod]);

  useEffect(() => {
    dispatch({ type: CART_TOGGLE_ALL_TERMS, payload: termsAllAccepted });
  }, [termsAllAccepted]);

  const termsOptionList = [
    // {
    //   label: <>Akceptuje wszystkie zgody</>,
    //   name: 'termsAllAccepted',
    //   value: termsAllAccepted,
    // },
    {
      label: (
        <>
          Zapoznałem się z{' '}
          <MULink href='https://www.fitdiet.pl/regulamin' target={'_blank'}>
            regulaminem
          </MULink>{' '}
          FitDiet
        </>
      ),
      name: 'termsFitDiet',
      value: termsFitDiet,
    },
    {
      label: (
        <>
          Oświadczam, że zapoznałem się z{' '}
          <MULink href='https://www.przelewy24.pl/regulamin'>
            regulaminem{' '}
          </MULink>{' '}
          i{' '}
          <MULink href='https://www.przelewy24.pl/obowiazek-informacyjny-platnik'>
            obowiązkiem informacyjnym
          </MULink>{' '}
          Przelewy24
        </>
      ),
      name: 'termsP24',
      value: termsP24,
      isHidden: isTermsP24Hidden,
    },
    // {
    //   label: (
    //     <>
    //       Zgoda na <Link to='#'>marketing</Link>
    //     </>
    //   ),
    //   name: 'termsMarketing',
    //   value: termsMarketing,
    // },
  ];

  const handleChangeTerms = (name, value) => {
    dispatch({
      type: CART_TOGGLE_VALUE,
      payload: { name: name, value: value },
    });
  };

  // const handlePayment = () => {};
  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.subtitle}>
        Twoja płatność
      </Typography>
      <div>
        <CustomizedRadios
          label='Wybierz sposób płatności'
          optionList={paymentOptions}
          changeHandler={(newPaymentMethod) =>
            dispatch({
              type: CART_UPDATE_PAYMENT,
              payload: newPaymentMethod,
            })
          }
          value={paymentMethod}
        />
      </div>
      <CustomizedCheckBox
        // label='Zgody'
        optionList={termsOptionList}
        handleChange={handleChangeTerms}
      />
    </div>
  );
};

export default Payment;
