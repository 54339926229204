//cart items
export const CART_ITEM_INIT = 'CART_ITEM_INIT';
export const CART_ITEM_UPDATE_CALORIES = 'CART_ITEM_UPDATE_CALORIES';
export const CART_ITEM_SET_DAILY_DISCOUNT = 'CART_ITEM_SET_DAILY_DISCOUNT';
export const CART_ITEM_UPDATE_SCHEDULE = 'CART_ITEM_UPDATE_SCHEDULE';
export const CART_ITEM_TOGGLE = 'CART_ITEM_TOGGLE';
export const CART_ITEM_TRIAL_TOGGLE = 'CART_ITEM_TRIAL_TOGGLE';
export const CART_ITEM_MOUSE_ENTER_DAY = 'CART_ITEM_MOUSE_ENTER_DAY';
export const CART_ITEM_SET_DAYS = 'CART_ITEM_SET_DAYS';
export const CART_ITEM_CLEAR_DAYS = 'CART_ITEM_CLEAR_DAYS';
export const CART_ITEM_PRICE_UPDATE = 'CART_ITEM_PRICE_UPDATE';
export const CART_ITEM_ADD_QTY = ' CART_ITEM_ADD_QTY';
export const CART_ITEM_SUBSTRACT_QTY = 'CART_ITEM_SUBSTRACT_QTY';
export const CART_ITEM_RESET = 'CART_ITEM_RESET';
