import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import plLocale from '@fullcalendar/core/locales/pl';
import { Box } from '@mui/material';

interface Day {
  date: string;
  status: string;
  events: string[];
  deliveredAt: string;
  _id: string;
}

const convertDay = (day: Day) => {
  let color;
  let textColor = 'white';
  switch (day.status) {
    case 'Planowany':
      color = 'green';
      break;
    case 'Dostarczony':
      color = 'grey';
      break;
    case 'Zawieszony':
      color = 'orange';
      textColor = 'black';
      break;
    case 'Płatność':
      color = 'yellow';
      textColor = 'black';
      break;
    case 'Anulowany':
      color = 'red';
      break;
    case 'Niedostarczony':
      color = 'brown';
      break;
    default:
      color = 'blue';
      break;
  }
  return {
    title: `${day.status}`,
    start: day.deliveredAt,
    allDay: true,
    color: color,
    textColor: textColor,
    id: day._id,
    display: 'block',
  };
};

const Calendar = ({
  deliveryDays,
  handleClickDay,
}: {
  deliveryDays: Day[];
  handleClickDay: (info: EventClickArg) => void;
}) => {
  const daysConverted = deliveryDays.map((d) => convertDay(d));
  // const [daysConverted, setDaysConverted] = useState(
  //   deliveryDays.map((d) => convertDay(d))
  // );

  //   const daysConverted = deliveryDays.map((d) => convertDay(d));

  return (
    <Box sx={{ m: 2 }}>
      <FullCalendar
        locale={plLocale}
        initialDate={new Date()}
        initialView='dayGridMonth'
        // defaultView='dayGridMonth'
        plugins={[dayGridPlugin]}
        // plugins={[dayGridPlugin, interactionPlugin]}
        // editable={true}
        // allDay
        events={daysConverted}
        // events={daysConverted}
        // eventDrop={handleMove}
        eventClick={(info) => handleClickDay(info)}
      />
    </Box>
  );
};

export default Calendar;
