import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { resetPassword } from '../actions/authActions';
import {
  AUTH_RESET_PASSWORD_FAIL,
  AUTH_CLEAR_ERROR,
} from '../constants/authConstants';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword({ match }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, isPasswordChanged } = useSelector(
    (state) => state.auth
  );
  const initValues = {
    password: '',
    confirmPassword: '',
  };
  const [values, setValues] = useState(initValues);
  const { password, confirmPassword } = values;
  const { resettoken } = match.params;

  useEffect(() => {
    if (isPasswordChanged) {
      setValues(initValues);
      history.push('/logowanie');
    }
  }, [isPasswordChanged]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const clickSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      return dispatch({
        type: AUTH_RESET_PASSWORD_FAIL,
        payload: 'Oba hasła muszą być takie same',
      });
    }
    if (password.length < 6)
      return dispatch({
        type: AUTH_RESET_PASSWORD_FAIL,
        payload: 'Nowe hasło jest za krótkie - musi mieć minimum 6 znaków',
      });

    dispatch(resetPassword(password, resettoken));
  };

  const handleCloseMessage = () => dispatch({ type: AUTH_CLEAR_ERROR });

  return (
    <div>
      {/* Ta walidacja jest nie potrzebna  */}
      {error && (
        <Message
          message={error}
          handleClose={handleCloseMessage}
          type={error ? 'error' : 'success'}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Wpisz nowe hasło
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                type='password'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Nowe hasło'
                name='password'
                autoComplete='email'
                autoFocus
                onChange={handleChange}
                value={password}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                type='password'
                id='email'
                label='Powtórz hasło'
                name='confirmPassword'
                autoComplete='email'
                onChange={handleChange}
                value={confirmPassword}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={clickSubmit}
              >
                Zmień hasło
              </Button>
            </form>
          </div>
          {isPasswordChanged && <Link to='/logowanie'> Zaloguj się </Link>}
        </Container>
      )}
    </div>
  );
}
