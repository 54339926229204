//cart items
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_PRICE_UPDATE = 'CART_PRICE_UPDATE';

export const CART_UPDATE_PAYMENT = 'CART_UPDATE_PAYMENT';
export const CART_SET_ADDRESS = 'CART_SET_ADDRESS';

export const CART_LOAD_REQUEST = 'CART_LOAD_REQUEST';
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export const CART_LOAD_LOCAL = 'CART_LOAD_LOCAL';
export const CART_LOAD_FAIL = 'CART_LOAD_FAIL';

export const CART_SAVE_REQUEST = 'CART_SAVE_REQUEST';
export const CART_SAVE_SUCCESS = 'CART_SAVE_SUCCESS';
export const CART_SAVE_FAIL = 'CART_SAVE_FAIL';

export const CART_CREATE_ORDER_REQUEST = 'CART_CREATE_ORDER_REQUEST';
export const CART_CREATE_ORDER_SUCCESS = 'CART_CREATE_ORDER_SUCCESS';
export const CART_CREATE_ORDER_FAIL = 'CART_CREATE_ORDER_FAIL';

export const CART_REGISTER_PAYMENT_REQUEST = 'CART_REGISTER_PAYMENT_REQUEST';
export const CART_REGISTER_PAYMENT_SUCCESS = 'CART_REGISTER_PAYMENT_SUCCESS';
export const CART_REGISTER_PAYMENT_FAIL = 'CART_REGISTER_PAYMENT_FAIL';

export const CART_IS_TRIAL_REQUEST = 'CART_IS_TRIAL_REQUEST';
export const CART_IS_TRIAL_SUCCESS = 'CART_IS_TRIAL_SUCCESS';
export const CART_IS_TRIAL_FAIL = 'CART_IS_TRIAL_FAIL';

export const CART_TOGGLE_VALUE = 'CART_TOGGLE_VALUE';
export const CART_TOGGLE_ALL_TERMS = 'CART_TOGGLE_ALL_TERMS';

export const CART_CLEAR = 'CART_CLEAR';

export const CART_SET_ERROR = 'CART_SET_ERROR';
export const CART_CLEAR_ERROR = 'CART_CLEAR_ERROR';
