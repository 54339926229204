import React from 'react';
import { makeStyles } from '@mui/styles';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Collapse } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CustomizedCheckBox({
  label,
  optionList,
  handleChange,
}) {
  const classes = useStyles();

  const handleChangeForm = (event) => {
    handleChange(event.target.name, event.target.checked);
    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  //   const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <div className={classes.root}>
      <FormControl
        // required
        // error={error}
        component='fieldset'
        className={classes.formControl}
      >
        <FormLabel component='legend'>{label}</FormLabel>
        <FormGroup>
          {optionList.map(
            (o) => (
              //   !o.isHidden && (
              <Collapse in={!o.isHidden} key={o.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={o.value}
                      onChange={handleChangeForm}
                      name={o.name}
                    />
                  }
                  label={o.label}
                />
              </Collapse>
            )
            //   )
          )}
        </FormGroup>
        {/* <FormHelperText>{errorTxt}</FormHelperText> */}
      </FormControl>
    </div>
  );
}
