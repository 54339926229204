import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const AddDietButton = ({ text }) => {
  let history = useHistory();
  const classes = useStyles();
  const handleClick = () => history.push('/sklep');
  return (
    <div>
      <Button
        variant='outlined'
        // color='primary'
        size='large'
        // className={classes.button}
        startIcon={<AddIcon color='secondary' />}
        onClick={handleClick}
      >
        {text ? text : 'Dodaj'}
      </Button>
    </div>
  );
};

export default AddDietButton;
