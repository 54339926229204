import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    textAlign: 'center',

    // display: 'flex',
    // alignContent: 'center',
  },
  container: {
    width: 150,
    margin: 'auto',
  },
  daysCountDiv: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  dietCountNubmer: {
    margin: 'auto',
  },
}));

const Qty = ({ handleAddDiets, handleRemoveDiets, qty }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Typography>Liczba diet:</Typography>
        </div>
        <div className={classes.daysCountDiv}>
          <Fab
            size='small'
            color='secondary'
            aria-label='minus'
            className={classes.margin}
            onClick={handleRemoveDiets}
          >
            <RemoveIcon />
          </Fab>

          <div className={classes.dietCountNubmer}>
            <Typography variant='h5'>{qty}</Typography>
          </div>
          <Fab
            size='small'
            color='secondary'
            aria-label='add'
            className={classes.margin}
            onClick={handleAddDiets}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default Qty;
