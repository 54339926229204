import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { updatePassword } from '../../actions/authActions';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const PasswordEdit = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector((state) => state.userDetails);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id='simple-dialog-title'>Zmień hasło</DialogTitle>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          reNewPassword: '',
        }}
        validationSchema={Yup.object({
          currentPassword: Yup.string()
            .required('Proszę uzupełnij hasło')
            .min(6, 'Hasło musi mieć co najmniej 6 znaków'),
          newPassword: Yup.string()
            .required('Proszę uzupełnij nowe hasło')
            .min(6, 'Nowe hasło musi mieć co najmniej 6 znaków'),
          reNewPassword: Yup.string().required('Proszę wpisz ponownie hasło'),
        })}
        validate={(values) => {
          const errors = {};
          if (values.newPassword !== values.reNewPassword)
            errors.reNewPassword = 'Hasła są rózne';
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { currentPassword, newPassword } = values;
          dispatch(updatePassword(currentPassword, newPassword));
          setSubmitting(false);
          onClose();
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, values }) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={TextField}
                  name='currentPassword'
                  type='password'
                  label='Obecne hasło'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='newPassword'
                  type='password'
                  label='Nowe hasło'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='reNewPassword'
                  type='password'
                  label='Powtórz nowe hasło'
                  fullWidth
                  variant='outlined'
                />
              </DialogContent>
              <DialogActions className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => onClose()}
                >
                  Zamknij
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Zapisz
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PasswordEdit;
