import { CART_ITEM_UPDATE_CALORIES } from '../constants/cartItemConstants';

export const updateCaloriesCart = (newCalories) => (dispatch, getState) => {
  const {
    productDetails: { product },
  } = getState();
  if (newCalories !== null && product) {
    // Find right daily price
    const productCalories = product.calories.find(
      (c) => c.variant === newCalories
    );
    const newDailyPrice = productCalories.price;
    const trialPrice = productCalories.trialPrice
      ? productCalories.trialPrice
      : newDailyPrice;

    dispatch({
      type: CART_ITEM_UPDATE_CALORIES,
      payload: { newCalories, newDailyPrice, trialPrice },
    });
  }
};
