import { combineReducers } from 'redux';

import authReducer from './authReducer';

import { productListReducer, productDetailsReducer } from './productReducer';
import userDetailsReducer from './userReducers';
import cartItemReducer from './cartItemReducer';
import cartReducer from './cartReducer';
import orderListReducer from './orderListReducer';
import orderItemReducer from './orderItemReducer';
import dietListReducer from './dietListReducer';

import { optionListReducer } from './optionReducers';

export default combineReducers({
  // alert,
  auth: authReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  userDetails: userDetailsReducer,
  dietList: dietListReducer,
  cartItem: cartItemReducer,
  cart: cartReducer,
  orders: orderListReducer,
  orderItem: orderItemReducer,
  optionList: optionListReducer,
});
