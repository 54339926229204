import React from 'react';

import CustomizedRadios from '../layout/CustomizedRadios';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CaloriesSelection = ({
  productCalories,
  currentSelection,
  changeHandler,
}) => {
  const classes = useStyles();

  const options = productCalories.map((c) => ({
    name: `${c.variant} kcal`,
    value: c.variant,
    // priceChange: '+1',
  }));
  return (
    <div className={classes.buttonGroup}>
      <CustomizedRadios
        label='Wybierz swoją kaloryczność'
        optionList={options}
        changeHandler={changeHandler}
        value={currentSelection}
      />
    </div>
  );
};

export default CaloriesSelection;
