import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
  ORDERS_CLEAR_ERROR,
} from '../constants/orderListConstants';

import produce from 'immer';

const initOrder = {
  orders: [],
  loading: null,
  error: null,
};

const orderListReducer = produce((draft, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case ORDERS_REQUEST:
      draft.loading = true;
      return;
    case ORDERS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.orders = payload;
      return;
    case ORDERS_FAIL:
      draft.loading = false;
      draft.error = payload;
      return;
    case ORDERS_CLEAR_ERROR:
      draft.error = null;
      return;
  }
}, initOrder);

export default orderListReducer;
