export const GET_PRODUCTS_BY_CATEGORY_REQUEST =
  'GET_PRODUCTS_BY_CATEGORY_REQUEST';
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS =
  'GET_PRODUCTS_BY_CATEGORY_SUCCESS';
export const GET_PRODUCTS_BY_CATEGORY_FAIL = 'GET_PRODUCTS_BY_CATEGORY_FAIL';
export const RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST';

export const GET_PRODUCT_DETAILS_REQUEST = 'GET_PRODUCT_DETAILS_REQUEST';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_FAIL = 'GET_PRODUCT_DETAILS_FAIL';

export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET';
