import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAddress } from '../../actions/userActions';
import AddressEdit from './AddressEdit/AddressEdit';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AddressAdd from './AddressAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginBottom: theme.spacing(2),
    margin: 'auto',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
    marginRight: theme.spacing(2),
  },
  button: {
    width: 'fit-content',
    margin: 'auto',
  },
  addressComments: {
    marginTop: theme.spacing(0),
  },
  noAddressComment: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    // margin: 'auto',
  },
  cardContentNoAddr: {
    padding: theme.spacing(3),
  },
}));

const AddressCard = ({ addresses }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editedAddressId, setEditedAddressId] = useState();
  // const [isAddressNewOpen, setIsAddressNewOpen] = useState(false);

  return (
    <div>
      {addresses?.length
        ? addresses.map((a, i) => (
            <Card className={classes.root} key={a._id}>
              <CardContent>
                <div className={classes.titleContainer}>
                  <Avatar className={classes.avatar}>
                    <HomeWorkIcon />
                  </Avatar>
                  <Typography variant='h5' color='textSecondary'>
                    {i === 0 ? 'Mój adres' : `Mój inny adres (${i})`}
                  </Typography>
                </div>
                <Typography>{`${a.street} ${a.buildingNr || ''} ${
                  a.aptNr ? `lok. ${a.aptNr} ` : ''
                }`}</Typography>
                <Typography>
                  {a.zipCode} {a.city}
                </Typography>
                <Typography>{a.voivodeship}</Typography>
                {a.toTime ? (
                  <Typography color='textSecondary'>{`Preferowana dostawa do godz: ${a.toTime}`}</Typography>
                ) : null}
                <Typography
                  color='textSecondary'
                  className={classes.addressComments}
                >
                  {a.deliveryPrice > 0
                    ? `Koszt pojedynczej dostawy na ten adres to ${a.deliveryPrice}`
                    : 'Dostawa bezpłatna!'}
                </Typography>
                <Typography
                  color='textSecondary'
                  className={classes.addressComments}
                >
                  {a.comments}
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => setEditedAddressId(a._id)} size='small'>
                  Zmień
                </Button>
                {addresses?.length > 1 && (
                  <Button
                    onClick={() => dispatch(deleteAddress(a._id))}
                    size='small'
                  >
                    Usuń
                  </Button>
                )}
              </CardActions>
            </Card>
          ))
        : ''}
      <Card className={classes.root}>
        <CardContent className={classes.cardContentNoAddr}>
          {!addresses?.length && (
            <Typography
              color='secondary'
              align='center'
              className={classes.noAddressComment}
            >
              Dodaj adres dostawy, aby zrealizować zamówienie!
            </Typography>
          )}
          <AddressAdd />
        </CardContent>
      </Card>

      {editedAddressId && (
        <AddressEdit
          open={editedAddressId}
          onClose={() => setEditedAddressId()}
          addressId={editedAddressId}
        />
      )}
    </div>
  );
};

export default AddressCard;
