import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { loadUser } from '../../actions/authActions';
import { updateUser } from '../../actions/userActions';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(3),
  },
}));

const UserEdit = ({ onClose, open, userId, fName, sName, phone, email }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { user } = useSelector((state) => state.userDetails);

  return (
    <Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      {/* <div className={classes.title}>
        <Avatar className={classes.avatar}>
          <PersonIcon />
        </Avatar> */}
      <DialogTitle id='simple-dialog-title'>Zmień swoje dane</DialogTitle>
      {/* </div> */}
      <Formik
        initialValues={{
          fName,
          sName,
          email,
          phone,
        }}
        validationSchema={Yup.object({
          fName: Yup.string().required('Proszę uzupełnij imię'),
          sName: Yup.string().required('Proszę uzupełnij nazwisko'),
          // email: Yup.string()
          //   .email('Błędny email')
          //   .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, {
          //     message: 'Podaj prawidłowy email',
          //   })
          //   .required('Proszę uzupełnij swój email'),

          phone: Yup.string()
            .matches(/^(?:\(?\?)?(?:[-\.\(\)\s]*(\d)){9}\)?$/, {
              message: 'Podaj prawidłowy telefon w formacie xxx-xxx-xxx',
            })
            .required('Proszę uzupełnij telefon'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            updateUser({
              ...user,
              ...values,
            })
          );
          // dispatch(loadUser());
          setSubmitting(false);
          onClose();
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, values }) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={TextField}
                  name='fName'
                  type='text'
                  label='Imię'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='sName'
                  type='text'
                  label='Nazwisko'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
                <Field
                  component={TextField}
                  name='email'
                  type='email'
                  label='Email'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                  disabled
                />
                <Field
                  component={TextField}
                  name='phone'
                  type='text'
                  label='Telefon'
                  fullWidth
                  variant='outlined'
                  className={classes.field}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => onClose()}
                >
                  Zamknij
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Zapisz
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserEdit;
