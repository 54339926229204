import {
  CART_ITEM_INIT,
  CART_ITEM_UPDATE_CALORIES,
  CART_ITEM_SET_DAILY_DISCOUNT,
  CART_ITEM_UPDATE_SCHEDULE, //update range,
  CART_ITEM_TOGGLE,
  CART_ITEM_TRIAL_TOGGLE,
  CART_ITEM_MOUSE_ENTER_DAY,
  CART_ITEM_SET_DAYS,
  CART_ITEM_CLEAR_DAYS,
  CART_ITEM_PRICE_UPDATE,
  CART_ITEM_ADD_QTY,
  CART_ITEM_SUBSTRACT_QTY,
  CART_ITEM_RESET,
} from '../constants/cartItemConstants';
import produce from 'immer';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import { createSelectedDays } from '../utils/schedule';

const initCurrentCartItem = {
  product: {},
  name: '',
  qty: 1,
  isTrialDay: false,
  priceBase: 0,
  price: 0,
  calories: 0,
  isSaturdayBlocked: false,
  isSundayBlocked: false,
  scheduleMode: 'range', //range or multiple
  deliveryDays: [],
  range: { start: null, end: null, enteredTo: null },
  dailyRate: 0,
  dailyRateBase: 0,
  dailyRateDiscount: 0,
  dailyRateDelivery: 0,
  dailyRateAddOn: 0,
  trialPrice: 0,
};

const cartItemReducer = produce((draft, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case CART_ITEM_INIT:
      draft.calories = payload.calories;
      draft.qty = payload.qty;
      draft.scheduleMode = payload.scheduleMode;
      draft.isSaturdayBlocked = payload.isSaturdayBlocked;
      draft.isSundayBlocked = payload.isSundayBlocked;
      draft.isTrialDay = payload.isTrialDay;
      draft.deliveryDays = payload.deliveryDays;
      return;

    case CART_ITEM_UPDATE_CALORIES:
      draft.calories = payload.newCalories;
      draft.dailyRateBase = payload.newDailyPrice;
      draft.trialPrice = payload.trialPrice;
      return;

    case CART_ITEM_SET_DAILY_DISCOUNT:
      if (draft.isTrialDay) {
        draft.dailyRateDiscount = draft.dailyRateBase - draft.trialPrice;
      } else {
        draft.dailyRateDiscount = payload;
      }
      return;

    case CART_ITEM_UPDATE_SCHEDULE:
      draft.scheduleMode = payload;
      return;

    case CART_ITEM_TOGGLE:
      draft[payload.name] = payload.isChecked;
      return;

    case CART_ITEM_TRIAL_TOGGLE:
      draft.isTrialDay = payload;
      draft.deliveryDays = [];
      draft.range = { start: null, end: null };
      if (payload) {
        //  draft.dailyRate = draft.trialPrice;
        // draft.dailyRateDiscount = draft.dailyRateBase - draft.trialPrice;
        draft.scheduleMode = 'multiple';
        draft.qty = 1;
      } else {
        draft.scheduleMode = 'range';
      }
      return;

    case CART_ITEM_MOUSE_ENTER_DAY:
      draft.deliveryDays = createSelectedDays(
        draft.range.start,
        payload,
        draft.isSaturdayBlocked,
        draft.isSundayBlocked
      );
      return;

    case CART_ITEM_SET_DAYS:
      const { day, selected } = payload;
      const { start, end } = draft.range;
      //zaznaczanie i odznaczanie dni w przypadku gdy jest zaznaczony range lub wybor pojedynczych dni
      if (draft.scheduleMode === 'multiple' || (start && end)) {
        if (selected) {
          const dayIdx = draft.deliveryDays.findIndex((d) => isSameDay(d, day));
          draft.deliveryDays.splice(dayIdx, 1);
        } else {
          draft.deliveryDays.push(day);
        }
      } else {
        //range mode
        //start of the range
        if ((!start && !end) || (start && end)) {
          draft.range.start = day;
          draft.deliveryDays = createSelectedDays(
            day,
            day,
            draft.isSaturdayBlocked,
            draft.isSundayBlocked
          );
        }
        //end of the range
        if (start && !end && isAfter(day, start)) {
          draft.range.end = day;
          draft.deliveryDays = createSelectedDays(
            start,
            day,
            draft.isSaturdayBlocked,
            draft.isSundayBlocked
          );
        }
      }
      //if trial day is selected only 1 day can be selected on cal
      if (draft.isTrialDay) {
        draft.deliveryDays = [day];
      }
      return;

    case CART_ITEM_CLEAR_DAYS:
      draft.deliveryDays = [];
      draft.range = { start: null, end: null, enteredTo: null };
      return;

    case CART_ITEM_PRICE_UPDATE:
      const { dailyRateBase, dailyRateDiscount, deliveryDays, qty } = draft;
      draft.dailyRate = dailyRateBase - dailyRateDiscount;
      draft.priceBase = dailyRateBase * deliveryDays.length * qty;
      draft.price = draft.dailyRate * deliveryDays.length * qty;
      return;

    case CART_ITEM_ADD_QTY:
      draft.qty++;
      if (draft.isTrialDay) draft.qty = 1;
      return;

    case CART_ITEM_SUBSTRACT_QTY:
      draft.qty--;
      if (draft.qty < 1) draft.qty = 1;
      return;

    case CART_ITEM_RESET:
      return initCurrentCartItem;
  }
}, initCurrentCartItem);

export default cartItemReducer;
