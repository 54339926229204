import {
  OPTION_GET_CITIES_REQUEST,
  OPTION_GET_CITIES_SUCCESS,
  OPTION_GET_CITIES_FAIL,
  OPTION_DIET_DAILY_DISCOUNTS_REQUEST,
  OPTION_DIET_DAILY_DISCOUNTS_SUCCESS,
  OPTION_DIET_DAILY_DISCOUNTS_FAIL,
  OPTION_DELIVERY_ZONE_REQUEST,
  OPTION_DELIVERY_ZONE_SUCCESS,
  OPTION_DELIVERY_ZONE_FAIL,
  OPTION_DELIVERY_PRICE_REQUEST,
  OPTION_DELIVERY_PRICE_SUCCESS,
  OPTION_DELIVERY_PRICE_FAIL,
  OPTION_HOURS_TO_REQUEST,
  OPTION_HOURS_TO_SUCCESS,
  OPTION_HOURS_TO_FAIL,
  OPTION_DELIVERY_ZONE_RESET,
} from '../constants/optionConstants';

export const optionListReducer = (
  state = {
    loading: false,
    error: null,
    // options: [],

    dailyDiscounts: [],
    deliveryZone: {},
    cities: [],
    deliveryPrice: {},
    hoursTo: [],
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case OPTION_GET_CITIES_REQUEST:
    case OPTION_DIET_DAILY_DISCOUNTS_REQUEST:
    case OPTION_DELIVERY_ZONE_REQUEST:
    case OPTION_DELIVERY_PRICE_REQUEST:
    case OPTION_HOURS_TO_REQUEST:
      return { ...state, loading: true };

    case OPTION_DIET_DAILY_DISCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyDiscounts: payload,
      };
    case OPTION_DELIVERY_ZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryZone: payload,
      };

    case OPTION_GET_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: payload,
      };
    case OPTION_DELIVERY_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPrice: payload,
      };
    case OPTION_HOURS_TO_SUCCESS:
      return {
        ...state,
        loading: false,
        hoursTo: payload,
      };

    case OPTION_DIET_DAILY_DISCOUNTS_FAIL:
    case OPTION_DELIVERY_ZONE_FAIL:
    case OPTION_GET_CITIES_FAIL:
    case OPTION_DELIVERY_PRICE_FAIL:
    case OPTION_HOURS_TO_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case OPTION_DELIVERY_ZONE_RESET:
      return {
        ...state,
        deliveryZone: {},
      };
    default:
      return state;
  }
};
