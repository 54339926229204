import api from '../utils/api';
import {
  CART_CREATE_ORDER_REQUEST,
  CART_CREATE_ORDER_SUCCESS,
  CART_CREATE_ORDER_FAIL,
  CART_REGISTER_PAYMENT_REQUEST,
  CART_REGISTER_PAYMENT_SUCCESS,
  CART_REGISTER_PAYMENT_FAIL,
  CART_LOAD_REQUEST,
  CART_LOAD_SUCCESS,
  CART_LOAD_FAIL,
  CART_SAVE_REQUEST,
  CART_SAVE_SUCCESS,
  CART_SAVE_FAIL,
  CART_IS_TRIAL_REQUEST,
  CART_IS_TRIAL_SUCCESS,
  CART_IS_TRIAL_FAIL,
  CART_CLEAR,
} from '../constants/cartConstants';

export const createOrder = (userId, newOrder) => async (dispatch) => {
  try {
    dispatch({ type: CART_CREATE_ORDER_REQUEST });
    const res = await api.post(`/users/${userId}/orders`, newOrder);
    dispatch({
      type: CART_CREATE_ORDER_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: CART_CREATE_ORDER_FAIL,
      payload: 'Błąd przy tworzeniu zamówienia',
    });
  }
};

export const registerPayment = (newOrder) => async (dispatch) => {
  try {
    dispatch({ type: CART_REGISTER_PAYMENT_REQUEST });
    const res = await api.post(`/payments/${newOrder._id}`, newOrder);
    dispatch({
      type: CART_REGISTER_PAYMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: CART_REGISTER_PAYMENT_FAIL,
      payload: 'Problem z płatnością',
    });
  }
};

export const loadCart = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CART_LOAD_REQUEST });
    let loadedCart;
    // do zastanowienia: czy jesli jest uzytkownik zalogowany to koszyk nie powinien byc pobierany z bazy
    if (localStorage.cart) {
      loadedCart = JSON.parse(localStorage.getItem('cart'));
    } else if (userId) {
      const res = await api.get(`/users/${userId}/cart`);
      loadedCart = res.data.data;
    }
    if (loadedCart) {
      //validate cart
      const validatedCart = await api.post(`/users/cart-validate`, loadedCart);
      if (validatedCart && validatedCart.data.success) {
        dispatch({
          type: CART_LOAD_SUCCESS,
          payload: validatedCart.data.data,
          // payload: res.data.data,
        });
      } else {
        dispatch({
          type: CART_LOAD_FAIL,
          payload: 'Problem z weryfikacją koszyka',
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CART_LOAD_FAIL,
      payload: 'Problem z pobraniem koszyka',
    });
  }
};

export const saveCart = () => async (dispatch, getState) => {
  const { cart, auth } = getState();
  const { isAuthenticated, profile } = auth;

  try {
    dispatch({ type: CART_SAVE_REQUEST });
    let savedCart = cart.cart;
    if (isAuthenticated) {
      const res = await api.post(`/users/${profile._id}/cart`, cart.cart);
      // const res = await api.post(`/users/${userId}/cart`, cart.cart);
      savedCart = res.data.data;
    } else {
      const res = await api.post(`users/cart-validate`, savedCart);
      savedCart = res.data.data;
    }
    localStorage.setItem('cart', JSON.stringify(savedCart));

    dispatch({
      type: CART_SAVE_SUCCESS,
      payload: savedCart,
      // payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CART_SAVE_FAIL,
      payload: 'Problem z odświeżniem koszyka',
    });
  }
};

export const checkTrialDay = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CART_IS_TRIAL_REQUEST });
    const res = await api.get(`/users/${userId}/cart/is-trial-day`);
    const isTrialDay = res.data.data;
    dispatch({
      type: CART_IS_TRIAL_SUCCESS,
      payload: isTrialDay,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CART_IS_TRIAL_FAIL,
      payload: 'Problem z odświeżniem koszyka',
    });
  }
};

export const clearCart = () => async (dispatch) => {
  dispatch({ type: CART_CLEAR });
  localStorage.setItem('cart', '');
};
